import React, { useMemo } from 'react'
import { I18nextProvider } from 'react-i18next'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
function configureLanguage({ languages = ['en'] }) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: false, // Convert to true to get untranslated keys
      lowerCaseLng: true,
      whitelist: languages,
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        // addPath: "/locales/add/{{lng}}/{{ns}}",
        allowMultiLoading: true,
      },
      ns: [
        'login',
        'common',
        'countries',
        'incidents',
        'incident_types',
        'asset_types',
        'root_causes',
        'maps',
        'overview',
        'user',
      ],
      defaultNS: 'common',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      keySeparator: '.',
      returnEmptyString: false,
      detection: { order: ['localStorage', 'navigator', 'htmlTag'] },
    })

  i18n.on('languageChanged', (lang) => {
    window.lang = lang
  })

  return i18n
}

export default function LanguageProvider({ languages = ['en'], children }) {
  const i18n = useMemo(() => {
    return configureLanguage({ languages })
  }, [languages])

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
