import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentIncidents, getIncidents } from '../api/incidents'
import { setIncidents } from '../app/store/IncidentSlice'
import { hasFeature } from '../api/auth'
import usePolling from './usePolling'

const DEFAULT_POLLING_RATE_SECONDS = 60
const INCREASED_POLLING_RATE_SECONDS = 5
const INCREASED_POLLING_RATE_DURATION_SECONDS = 60

const useIncidentsLoader = () => {
  const [useHighPollingRate, setUseHighPollingRate] = useState(false)
  const dispatch = useDispatch()

  const { lastIncidentCreationTime } = useSelector((state) => state.incidents)

  const pollingRate = useHighPollingRate
    ? INCREASED_POLLING_RATE_SECONDS
    : DEFAULT_POLLING_RATE_SECONDS

  const fetchIncidents = useCallback(async () => {
    if (hasFeature('use_current_incidents_cache', true)) {
      return getCurrentIncidents()
    }

    let nextKey = null
    const incidents = []

    do {
      const response = await getIncidents({
        direction: 'next',
        key: nextKey,
      })

      if (response.incidents?.length) {
        incidents.push(...response.incidents)
      }

      nextKey = response.next
    } while (nextKey !== null)

    return incidents
  }, [])

  const { response, error, fetchTime } = usePolling(fetchIncidents, pollingRate)

  useEffect(() => {
    if (error) {
      // If there was an error fetching incidents, enable high polling rate
      // to try to recover from it as fast as possible
      setUseHighPollingRate(true)
      return
    }

    if (lastIncidentCreationTime) {
      // Enable high polling rate for 60 seconds after an incident is
      // created
      const timeoutEndTime = lastIncidentCreationTime.plus({
        seconds: INCREASED_POLLING_RATE_DURATION_SECONDS,
      })
      const now = DateTime.now()

      if (now < timeoutEndTime) {
        setUseHighPollingRate(true)

        const timeout = setTimeout(
          () => setUseHighPollingRate(false),
          timeoutEndTime - now,
        )

        // Clear timeout when component is unmounted
        return () => clearTimeout(timeout)
      }
    }

    // No error and no incident created in the last minute, use normal polling
    // rate
    setUseHighPollingRate(false)
  }, [error, lastIncidentCreationTime])

  useEffect(() => {
    if (response) {
      dispatch(
        setIncidents({
          incidents: response,
          fetchTime,
        }),
      )
    }
  }, [response, fetchTime, dispatch])
}

export default useIncidentsLoader
