import { combineReducers } from 'redux'

import AuthReducer from './store/AuthSlice'

import OrganizationSlice from './store/OrganizationSlice'
import AssetReducer from './store/AssetSlice'
import IncidentReducer from './store/IncidentSlice'
import OverviewReducer from './store/OverviewSlice'
import SearchReducer from './store/SearchSlice'

export default combineReducers({
  auth: AuthReducer,

  organizations: OrganizationSlice,
  assets: AssetReducer,
  incidents: IncidentReducer,
  overview: OverviewReducer,
  search: SearchReducer,
})
