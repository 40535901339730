// Use USA as map center when initializing
// This allows us to start loading useful tiles earlier
export const DEFAULT_MAP_BOUNDS = [[-124.788174, 27.51512], [-70.850609, 49.057494]];

export const computeBoundsForPolygon = (coordinates) => {
  let padding = 1;
  const outerRing = coordinates[0];
  const longitudes = outerRing.map(coordinate => coordinate[0])
  const latitudes = outerRing.map(coordinate => coordinate[1])

  return [
    [Math.min(...longitudes) - padding, Math.min(...latitudes) - padding],
    [Math.max(...longitudes) + padding, Math.max(...latitudes) + padding],
  ];
}

export const computeBoundsForMultiPolygon = (polygons) => {
  const longitudes = [];
  const latitudes = [];

  for (const polygon of polygons) {
    const [[miny, minx], [maxy, maxx]] = computeBoundsForPolygon(polygon);
    longitudes.push(miny, maxy);
    latitudes.push(minx, maxx);
  }

  return [
    [Math.min(...longitudes), Math.min(...latitudes)],
    [Math.max(...longitudes), Math.max(...latitudes)],
  ];
}

export const computeBoundsForIncidents = (incidents) => {
  const padding = 2
  let minLat = -90
  let maxLat = 90
  let minLng = -180
  let maxLng = 180

  incidents.forEach((incident) => {
    const locationData = incident.location.geojson.coordinates;
    const latitude = parseFloat(locationData[1])
    const longitude = parseFloat(locationData[0])

    minLat = (minLat < latitude) ? latitude : minLat
    maxLat = (maxLat > latitude) ? latitude : maxLat

    minLng = (minLng < longitude) ? longitude : minLng
    maxLng = (maxLng > longitude) ? longitude : maxLng
  })

  return [[minLng+padding, minLat+padding], [maxLng-padding, maxLat-padding]];
}

export const computeCentroidForPoints = (points) => {
  let x = 0, y = 0;

  for (const point of points) {
      x += point[0];
      y += point[1];
  }

  x /= points.length;
  y /= points.length;

  return [x, y];
}

export const computeCentroidForPolygon = (polygon) => {
  return computeCentroidForPoints(polygon[0])
}

export const computeCentroidForMultiPolygon = (polygons) => {
  const centroids = [];

  for (const polygon of polygons) {
    centroids.push(computeCentroidForPolygon(polygon))
  }

  return computeCentroidForPoints(centroids)
}

export const computeCentroid = (geo) => {
  if (!geo) {
    return [];
  }

  if (geo.type === 'Point') {
    return geo.coordinates;
  } else if (geo.type === 'Polygon') {
    return computeCentroidForPolygon(geo.coordinates);
  } else if (geo.type === 'MultiPolygon') {
    return computeCentroidForMultiPolygon(geo.coordinates);
  }
}

export const computeFirstPoint = (geo) => {
  if (!geo) {
    return [];
  }

  if (geo.type === 'Point') {
    return geo.coordinates;
  } else if (geo.type === 'Polygon') {
    return geo.coordinates[0][0];
  } else if (geo.type === 'MultiPolygon') {
    return geo.coordinates[0][0][0];
  }
}


export const roundLocation = (input, decimals) => {
  if (typeof input === 'string') {
    const nums = input.split(',');
    return nums.map(num => roundNumber(num, decimals)).join(', ');
  }
  return roundNumber(input, decimals);
}

export const roundNumber = (num, decimals) => {
  if(num){
  return Number(num.toFixed(decimals));
  }
  return num;
}

export const findKey = (obj, key) => {
  const stack = [obj];
  while (stack.length > 0) {
    const currentObj = stack.shift();
    if (currentObj[key] !== undefined) {
      return currentObj[key];
    }
    for (const prop in currentObj) {
      if (typeof currentObj[prop] === 'object') {
        stack.push(currentObj[prop]);
      }
    }
  }
  return false;
}