import { DateTime, Interval } from 'luxon'
import { useEffect, useRef } from 'react'

export function capitalizeMultiwordHelper(string) {
  if (string && string.length > 0) {
    let wordsArray = string.toLowerCase().split(' ')

    let capitalsArray = wordsArray.map((word) => {
      if (word[0] === undefined) {
        return ''
      }
      return word[0].toUpperCase() + word.slice(1)
    })
    return capitalsArray.join(' ')
  }

  return ''
}

function translateOrCapitalizeString(translationFunction, namespace, string) {
  return translationFunction(
    `${namespace}:${capitalizeMultiwordHelper(string)}`,
  )
}

export function formatIncidentType(translationFunction, incidentType) {
  return translateOrCapitalizeString(
    translationFunction,
    'incident_types',
    incidentType,
  )
}

export function formatAssetType(translationFunction, assetType) {
  return translateOrCapitalizeString(
    translationFunction,
    'asset_types',
    assetType,
  )
}

export function formatUtilityIssue(translationFunction, rootCause) {
  return translateOrCapitalizeString(
    translationFunction,
    'root_causes',
    rootCause,
  )
}

export function formatDateHelper(date, justDisplay = true) {
  const _dateObj = DateTime.fromISO(date)
  const display = _dateObj.toLocaleString({
    ...DateTime.DATETIME_SHORT,
    timeZoneName: 'short',
  })

  if (justDisplay) {
    return display
  } else {
    return { display, _dateObj }
  }
}

// Using ISO date strings or Luxon DateTimes, compute the duration
// of the interval in days, hours, and minutes.
// If endDate is not specified, it will be set to the current time.
// Returns null for an invalid date string.
export function computeDuration(startDate, endDate = null) {
  if (typeof startDate === 'string') {
    startDate = DateTime.fromISO(startDate)
  }

  if (!endDate) {
    endDate = DateTime.now()
  } else if (typeof endDate === 'string') {
    endDate = DateTime.fromISO(endDate)
  }

  if (!startDate.isValid || !endDate.isValid) {
    return null
  }

  const interval = Interval.fromDateTimes(startDate, endDate)
  return interval.toDuration(['days', 'hours', 'minutes'])
}

function formatTimeDifferenceHelper(dateA, dateB = null) {
  if (typeof dateA === 'string') {
    dateA = DateTime.fromISO(dateA)
  }

  if (!dateB) {
    dateB = DateTime.now()
  } else if (typeof dateB === 'string') {
    dateB = DateTime.fromISO(dateB)
  }

  let response = {
    diffDisplay: '',
    past: false,
  }

  let interval = null

  if (dateA <= dateB) {
    interval = Interval.fromDateTimes(dateA, dateB)
    response.past = true
  } else {
    interval = Interval.fromDateTimes(dateB, dateA)
  }

  const duration = interval.toDuration(['days', 'hours', 'minutes'])

  const days = parseInt(duration.days)
  const hours = parseInt(duration.hours)
  const minutes = parseInt(duration.minutes)

  if (days > 0) {
    response.diffDisplay = `${days} days`
  } else {
    response.diffDisplay = `${hours}h ${minutes}m`
  }

  if (response.past === true) {
    response.diffDisplay = response.diffDisplay + ' ago'
  }

  return response
}

export function formatETRHelper(translationFunction, incident, fetchTime) {
  if (!incident) return

  let etr = {
    timeDisplay: translationFunction('incidents:no_etr_yet'),
    absoluteTimeDisplay: '',
  }

  if (incident.incident_status_name === 'resolved') {
    etr.timeDisplay = translationFunction('incidents:resolved')
    if (!!incident.updated) {
      etr._dateObj = DateTime.fromISO(incident.updated)
      etr.absoluteTimeDisplay = formatDateHelper(etr._dateObj)
    }
  } else if (
    !!incident.incident_etr &&
    incident.incident_etr !== 'null' &&
    !incident.metadata.unstable_etr
  ) {
    etr._dateObj = DateTime.fromISO(incident.incident_etr)

    const { diffDisplay, past } = formatTimeDifferenceHelper(
      etr._dateObj,
      fetchTime,
    )

    if (past === false) {
      etr = { ...etr, timeDisplay: diffDisplay, past }
      etr.absoluteTimeDisplay = formatDateHelper(etr._dateObj)
    }
  }

  return etr
}

export function formatCityState(city, state) {
  let locationStr = ''
  if (!!city) locationStr += city
  if (!!city && !!state) {
    locationStr += ', '
  }
  if (!!state) locationStr += state

  return locationStr
}

/**
 *
 * @param Object location expects location to contain the following properties
 * thoroughfare, locality, administrative_area, and postal_code
 */
export function formatAddress(location) {
  let address = ''

  if (location.thoroughfare) address += location.thoroughfare + ', '
  if (location.locality && location.administrative_area)
    address += location.locality + ', ' + location.administrative_area + ' '
  if (location.postal_code) address += location.postal_code

  return address
}

export function getValidatingPowerStatus(incident, fetchTime) {
  if (
    incident.blockers.length === 0 &&
    incident.incident_status_name !== 'resolved'
  ) {
    const timeSinceCreated = computeDuration(incident.created, fetchTime)
    const soakTime =
      typeof window.GISUAL_POWER_ON_SOAK_TIME_MINS === 'undefined'
        ? 15
        : window.GISUAL_POWER_ON_SOAK_TIME_MINS

    // The created timestamp for newly created incidents might be in the future
    // if the clocks are not in sync, which makes timeSinceCreated invalid
    if (
      timeSinceCreated === null ||
      !timeSinceCreated.isValid ||
      timeSinceCreated.as('minutes') < soakTime
    ) {
      return 'validating power'
    }

    return 'power on'
  }

  // Validating power status is only valid for open incidents without blockers
  return null
}

export const validatePasswordRequirements = (password) => {
  const hasAtLeastEightCharacters = password.length > 7
  const hasAtLeastOneLowercase = password.search(/(?=.*[a-z])/) >= 0
  const hasAtLeastOneUppercase = password.search(/(?=.*[A-Z])/) >= 0
  const hasAtLeastOneNumber = password.search(/(?=.*[0-9])/) >= 0
  const hasAtLeastOneSymbol =
    password.search(/(?=.*[ ~`!@#$%^&*()\-_=+{}|:;"'<>?,./])/) >= 0
  const doesNotBeginOrEndWithWhitespace =
    password.length === password.trim().length

  return {
    hasAtLeastEightCharacters,
    hasAtLeastOneLowercase,
    hasAtLeastOneUppercase,
    hasAtLeastOneNumber,
    hasAtLeastOneSymbol,
    doesNotBeginOrEndWithWhitespace,
  }
}

export const getIncidentBlocker = (incident) => {
  if (!incident) {
    return null
  }

  if (incident.blockers.length === 0) {
    return null
  }

  if (incident.blockers.length === 1) {
    return incident.blockers[0]
  }

  let newest_blocker = null
  incident.blockers.forEach((blocker) => {
    if (
      newest_blocker === null ||
      blocker.correlated_at > newest_blocker.correlated_at
    ) {
      newest_blocker = blocker
    }
  })

  return newest_blocker
}

export const usePrevious = (value, initialValue = null) => {
  const ref = useRef(initialValue)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
