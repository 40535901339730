import { jwtDecode } from 'jwt-decode';
import mixpanel from 'mixpanel-browser'
import { post, get } from './client.js';
import { identifyUser } from './analytics.js'

import store from '../app/store'

import {
  saveItemsToLocalStorage,
  fetchItemsFromLocalStorage,
  fetchItemFromLocalStorage,
  removeItemsFromLocalStorage,
} from './../helpers/localStore'
import { setAllAuthState } from '../app/store/AuthSlice.js';
import { organizationHasAssets } from './assets.js';
import { getCurrentSession, setSessionUser } from './aaa.js';

const authLSPrefix = 'session'

let user = getEmptyUserObject()

function getEmptyUserObject() {
  return {
    uid: null,
    orgid: null,
    email: null,
    isAdmin: null,
    entitlements: {},
    features: {},
  }
}

export async function refreshAccessToken() {
  try {
    await post(
      '/refresh',
      {
        // Refresh token is sent in cookies
        data: {},
        shouldProcessError: false,
      },
    )

    // Refreshed access token is received in cookies

    return true
  } catch {
    window.location.replace('/logout')

    return false
  }
}

export function _sendForgotPasswordEmail(email) {
  const body = {
    email_address: email
  }

  return post(
    '/send-forgot-password-email',
    {
      data: body,
      shouldProcessError: false,
      withCredentials: false,
    },
  )
}

export async function resetPasswordToken(passwordResetToken, newPassword) {
  await post(
    '/reset-password/token',
    {
      data: {
        reset_token: passwordResetToken,
        new_password: newPassword,
      },
      shouldProcessError: false,
    },
  )

  if (await refreshAccessToken()) {
    await createSession()
  }
}

export async function resetPasswordSession(currentPassword, newPassword) {
  await post(
    '/reset-password/session',
    {
      data: {
        current_password: currentPassword,
        new_password: newPassword,
      },
      shouldProcessError: false,
    },
  )

  if (await refreshAccessToken()) {
    await createSession()
  }
}

function identifyUserInMixpanel() {
  identifyUser(user.uid, {
    '$email': user.email,
    'organization_id': user.orgid,
    'latest_login': Date.now(),
  })
}

export function getSessionUserIdFromLS() {
  return fetchItemFromLocalStorage('uid', authLSPrefix)
}

export function userIsAdmin() {
  if (!validateSession()) {
    return false
  }

  return fetchItemFromLocalStorage('isAdmin', authLSPrefix) === true
}

export function getEntitlements() {
  return fetchItemFromLocalStorage('entitlements', authLSPrefix)
}

export function hasEntitlement(entitlement) {
  if (!validateSession()) {
    return false
  }

  if (entitlement === null) {
    return true
  }

  const entitlements = getEntitlements()

  return entitlements !== null && entitlements[entitlement] === true
}

export function getFeatures() {
  return fetchItemFromLocalStorage('features', authLSPrefix)
}

export function hasFeature(feature, enabledByDefault) {
  if (!validateSession()) {
    return enabledByDefault
  }

  const features = getFeatures()

  return features[feature] ?? enabledByDefault
}

export function isTokenValid(token) {
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode(token);
  return decodedToken && decodedToken.exp*1000 > Date.now();
}

function isUserAdministrator(userData) {
  const user_groups = userData.groups.filter(
    (group) => userData.organization_id === group.organization_id
  );

  return user_groups.some(group => group.group_name === 'administrators')
}

async function createSession() {
  const sessionResponse = await getCurrentSession()
  const userData = sessionResponse.data.user
  const orgData = sessionResponse.data.organization

  user.uid = userData.user_id
  user.email = userData.email_address
  user.orgid = userData.organization_id
  user.isAdmin = isUserAdministrator(userData)
  user.entitlements = orgData.entitlements
  user.features = orgData.features

  saveItemsToLocalStorage({ entitlements: user.entitlements }, authLSPrefix)
  saveItemsToLocalStorage({ features: user.features }, authLSPrefix)

  setSessionUser(userData)
  saveItemsToLocalStorage(user, authLSPrefix)

  identifyUserInMixpanel()

  store.dispatch(setAllAuthState({
    isAuthenticated: true,
  }))

  return true
}

export async function verifyUserEmail(
  emailAddress,
  reCaptchaTokenV2,
  reCaptchaTokenV3,
) {
  const body = {
    email_address: emailAddress,
    recaptcha_token_v2: reCaptchaTokenV2,
    recaptcha_token_v3: reCaptchaTokenV3,
  }

  const response = await post(
    '/authenticate/email',
    {
      data: body,
      shouldProcessError: false,
      withCredentials: false,
    },
  )

  if (response.data.redirect_url) {
    window.location.replace(response.data.redirect_url);
    return true
  }

  return false
}

export async function verifyUserPassword(email_address, password) {
  const body = {
    email_address: email_address,
    password: password
  }

  await post(
    '/authenticate/user',
    {
      data: body,
      shouldProcessError: false,
    },
  )

  return await createSession()
}

export async function createSessionFromLoginToken(token) {
  const body = {
    'login_token': token,
  }

  await post(
    '/authenticate/token',
    {
      data: body,
      shouldProcessError: false,
    },
  )

  return await createSession()
}

export function restoreSession() {
  user = fetchItemsFromLocalStorage(Object.keys(user), authLSPrefix)

  const sessionIsValid = validateSession()

  store.dispatch(setAllAuthState({
    isAuthenticated: sessionIsValid,
  }))
}

export function validateSession() {
  return user.uid ? true : false
}

export async function performLogout() {
  if (!validateSession()) {
    return
  }

  try {
    const response = await get(
      '/logout',
      {
        shouldProcessError: false,
      },
    )

    if (response.data.redirect_url) {
      window.location.replace(response.data.redirect_url)
      return true
    }
  } catch {}

  return false
}

export function deleteSession() {
  user = getEmptyUserObject()

  removeItemsFromLocalStorage(Object.keys(user), authLSPrefix)

  store.dispatch(setAllAuthState({
    isAuthenticated: false,
  }))
}

export function endSession() {
  if (!!window.MIXPANEL_PROJECT_TOKEN) {
    mixpanel.reset()
  }

  return new Promise(function(resolve, reject) {
    deleteSession()
    resolve()
  })
}

export async function refreshDefaultRoute() {
  if (validateSession()) {
    const defaultRoute = await organizationHasAssets() ? '/map' : '/list'

    saveItemsToLocalStorage({ defaultRoute }, authLSPrefix)

    return defaultRoute
  }
}

export function getDefaultRoute() {
  return fetchItemFromLocalStorage('defaultRoute', authLSPrefix)
}
