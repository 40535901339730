import { DateTime } from 'luxon'
import { useState, useEffect, useCallback } from 'react'

/**
 * A custom hook to query an endpoint using polling.
 *
 * @param fetchFunction An async function that queries an endpoint.
 * If using a function defined inside a React component, wrap it
 * with useCallback.
 * @param pollingRateSeconds The polling rate. Polling is disabled
 * if this is set to a number <= 0.
 */
const usePolling = (fetchFunction, pollingRateSeconds) => {
  const [isFetching, setIsFetching] = useState(false)
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [fetchTime, setFetchTime] = useState()

  const performFetch = useCallback(async () => {
    const fetchStart = DateTime.now()

    try {
      setIsFetching(true)

      const response = await fetchFunction()

      setError(undefined)
      setResponse(response)
    } catch (error) {
      setResponse(undefined)
      setError(error.error)
    } finally {
      setIsFetching(false)
      setFetchTime(fetchStart)
    }
  }, [fetchFunction])

  // Perform initial fetch on mount
  useEffect(() => {
    performFetch()
  }, [performFetch])

  // Queue next fetch after the current one finishes
  useEffect(() => {
    if (!isFetching && pollingRateSeconds > 0) {
      const timeout = setTimeout(performFetch, pollingRateSeconds * 1000)

      return () => clearTimeout(timeout)
    }
  }, [isFetching, performFetch, pollingRateSeconds])

  return {
    isFetching,
    response,
    error,
    fetchTime,
  }
}

export default usePolling
