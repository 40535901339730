import { defaultTheme, mergeTheme } from "evergreen-ui"

const GisualEvergreenTheme = mergeTheme(defaultTheme, {
  components: {
    Tooltip: {
      baseStyle: {
        paddingX: 8,
        paddingY: 4,
        borderRadius: 'radii.1',
        elevation: 'shadows.3',
      },
      appearances: {
        default: {
          backgroundColor: '#2F3033',
          color: '#FEFEFE',
        },
      },
    },
  },
});

export default GisualEvergreenTheme
