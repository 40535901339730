import { createSlice } from '@reduxjs/toolkit'

const OrganizationSlice = createSlice({
  name: 'organizations',
  slice: 'organizations',
  initialState: {
    "utilities": {}
  },
  reducers: {
    addUtility(state, action) {
      let incoming = action.payload
      state.utilities[incoming.organization_id] = incoming
    },
    resetUtilities(state, action) {
      state.utilities = {}
    }
  }
})

export const { addUtility, resetUtilities } = OrganizationSlice.actions

export default OrganizationSlice.reducer
