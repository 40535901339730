import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { hasEntitlement, userIsAdmin, validateSession } from '../api/auth'

function PrivateRoute({ children, requiresAdmin = false, requiredEntitlement = null }) {
    const location = useLocation();
    const isAuth = validateSession();

    if (!children) {
        return null;
    }

    if (requiresAdmin && !userIsAdmin()) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (requiredEntitlement !== null && !hasEntitlement(requiredEntitlement)) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (!isAuth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default PrivateRoute;