import React, { createContext, useContext, useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MapContext } from 'react-mapbox-gl'

// Fetching Data for FlyTo URL Param
import { getOutageById } from '../../../../api/incidents'
import { getAsset } from '../../../../api/assets'
import { getOrganization } from '../../../../api/aaa'

import PopupContext from '../partials/PopupContext'

import {
  computeBoundsForPolygon,
  computeBoundsForMultiPolygon,
  computeCentroidForPolygon,
  computeCentroidForMultiPolygon,
} from '../../../../helpers/extents'

// Analytics events
import { recordAnalyticEvent } from '../../../../api/analytics'

export const MapExternalsContext = createContext()

export function MapExternalsProvider({ children }) {
  const navigate = useNavigate()

  const initialState = {
    loaded: {
      self: false,
      map: false,
    },
    viewportBounds: null,
    latestFitBounds: [],
    latestFlyTo: {
      center: [],
      zoom: 0,
    },
  }

  const mapExternalReducer = (state, action) => {
    switch (action.perform) {
      case 'init':
        return { ...state, loaded: { ...state.loaded, self: action.data } }
      case 'setMapLoad':
        return { ...state, loaded: { ...state.loaded, map: action.data } }
      case 'setViewportBounds':
        return { ...state, viewportBounds: action.data }
      case 'flyTo':
        return { ...state, latestFlyTo: action.data }
      case 'fitBounds':
        return { ...state, latestFitBounds: action.data }
      default:
        throw new Error(`Unexpected MapExternal call: ${action.perform}`)
    }
  }

  const [state, dispatch] = useReducer(mapExternalReducer, initialState)

  // If a user triggers a map action and the map is not loaded open the map.
  useEffect(() => {
    if (
      !!state.loaded.self &&
      !state.loaded.map &&
      (state.latestFitBounds.length > 0 || state.latestFlyTo.zoom !== 0)
    ) {
      navigate('/map')
      // The event is automatically dispatched as soon as the MapExternalsController and MapContext are fully loaded,
      // and whatever event you have just dispatched will be run and the state reset.
    }
    if (!state.loaded.self) {
      dispatch({ perform: 'init', data: true })
    }
  }, [state.latestFitBounds, state.latestFlyTo])

  const mapShare = { state, dispatch }

  return (
    <MapExternalsContext.Provider value={mapShare}>
      {children}
    </MapExternalsContext.Provider>
  )
}

export function MapExternalsController(props) {
  const map = useContext(MapContext)
  const { handleOpenPopup } = useContext(PopupContext)
  const { state, dispatch } = useContext(MapExternalsContext)
  const { t } = useTranslation('maps')

  useEffect(() => {
    dispatch({
      perform: 'setMapLoad',
      data: true,
    })

    // Perform the URL Param fly-to if we have a url to work with
    urlParamFlyTo(props.urlParams, dispatch, handleOpenPopup, t)

    return () => {
      dispatch({
        perform: 'setMapLoad',
        data: false,
      })
    }
  }, [])

  useEffect(() => {
    if (state.latestFitBounds.length > 0) {
      map.fitBounds({
        ...state.latestFitBounds,
        speed: 2.5,
        curve: 1,
      })
      dispatch({
        perform: 'fitBounds',
        data: [],
      })
    }
  }, [state.latestFitBounds])

  useEffect(() => {
    if (state.latestFlyTo.zoom !== 0) {
      map.flyTo({
        ...state.latestFlyTo,
        speed: 2.5,
        curve: 1,
      })
      dispatch({
        perform: 'flyTo',
        data: {
          zoom: 0,
        },
      })
    }
  }, [state.latestFlyTo])

  return <div />
}

async function urlParamFlyTo(urlParams, dispatch, handleOpenPopup, t) {
  if (!urlParams.id || urlParams.id === '') return

  recordAnalyticEvent('ID Link Visited on Map', {
    id: urlParams.id,
  })

  // DEFAULT Outage fetch behavior
  if (
    typeof urlParams.type === 'undefined' ||
    (!!urlParams.type && urlParams.type === 'outage')
  ) {
    let { data: incident } = await getOutageById(urlParams.id)

    if (incident) {
      // blockers doesnt exist (meaning this is an outage)
      if (incident.blockers.length === 0) {
        const { data: organization } = await getOrganization(
          incident.organization_id,
        )
        if (organization && incident.metadata) {
          const geojson = incident.location.geojson

          incident = {
            ...incident,
            organization_name: organization.organization_name,
          }

          switch (geojson.type) {
            case 'Point':
              incident = {
                ...incident,
                coordinates: {
                  lng: geojson.coordinates[0],
                  lat: geojson.coordinates[1],
                },
              }
              handleOpenPopup('outage', incident)

              dispatch({
                perform: 'flyTo',
                data: {
                  zoom: 16,
                  center: geojson.coordinates,
                },
              })
              break
            case 'Polygon':
              const singlebounds = computeBoundsForPolygon(geojson.coordinates)

              if (singlebounds) {
                dispatch({
                  perform: 'fitBounds',
                  data: singlebounds,
                })
              }

              break
            case 'MultiPolygon':
              const multibounds = computeBoundsForMultiPolygon(
                geojson.coordinates,
              )
              if (multibounds) {
                dispatch({
                  perform: 'fitBounds',
                  data: multibounds,
                })
              }
              break
            default:
              console.error(t('unsupported_geometry_type') + geojson.type)
          }
        }
      } else {
        handleOpenPopup('incident', incident)

        dispatch({
          perform: 'flyTo',
          data: {
            zoom: 16,
            center: incident.location.geojson.coordinates,
          },
        })
      }
    }
  } else if (
    typeof urlParams.type === 'undefined' ||
    (!!urlParams.type && urlParams.type === 'incident')
  ) {
    let { data: incident } = await getOutageById(urlParams.id)

    if (incident) {
      handleOpenPopup('incident', incident)

      dispatch({
        perform: 'flyTo',
        data: {
          zoom: 16,
          center: incident.location.geojson.coordinates,
        },
      })
    }
  }

  // Fetch Asset behavior
  else if (!!urlParams.type && urlParams.type === 'asset') {
    let { data: asset } = await getAsset(urlParams.id)

    if (asset && asset.asset_type_name === 'node') {
      let coordinates = null

      if (asset.location.geojson.type === 'Point') {
        dispatch({
          perform: 'flyTo',
          data: {
            zoom: 16,
            center: asset.location.geojson.coordinates,
          },
        })
        coordinates = asset.location.geojson.coordinates
      } else if (asset.location.geojson.type === 'Polygon') {
        dispatch({
          perform: 'fitBounds',
          data: computeBoundsForPolygon(asset.location.geojson.coordinates),
        })

        coordinates = computeCentroidForPolygon(
          asset.location.geojson.coordinates,
        )
      } else if (asset.location.geojson.type === 'MultiPolygon') {
        dispatch({
          perform: 'fitBounds',
          data: computeBoundsForMultiPolygon(
            asset.location.geojson.coordinates,
          ),
        })

        coordinates = computeCentroidForMultiPolygon(
          asset.location.geojson.coordinates,
        )
      }

      handleOpenPopup('asset', {
        ...asset.properties,
        coordinates: { lng: coordinates[0], lat: coordinates[1] },
      })
    }
  }

  return
}
