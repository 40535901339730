import React, { useContext } from 'react'
import { Layer, Source } from "react-mapbox-gl"
import { LayerFilterContext } from './Legend';

// Precipitation Layer from Open Weather Map
const OPEN_WEATHER_PRECIP_URL = `https://maps.openweathermap.org/maps/2.0/weather/PR0/{z}/{x}/{y}?palette=0.000005:FEF9CA;0.000009:B9F7A8;0.000014:93F57D;0.000023:78F554;0.000046:50B033;0.000092:387F22;0.000231:204E11;0.000400:FFFF1E;0.000694:FFA114;0.000926:FC8014;0.001388:EB4726;0.002315:B02318;0.023150:971D13&appid=${window.OPEN_WEATHER_API_KEY}`;
const OPEN_WEATHER_PRECIP_SOURCE_ID = "open-weather-precipitation-source";
export const OPEN_WEATHER_PRECIP_LAYER_ID = "open-weather-precipitation-layer";

export default function WeatherLayers() {
  const { showPrecipitationLayer } = useContext(LayerFilterContext)
  return (
    <React.Fragment>
      <Source id={OPEN_WEATHER_PRECIP_SOURCE_ID} tileJsonSource={{ type: "raster", tiles: [OPEN_WEATHER_PRECIP_URL] }} />
      <Layer type="raster" id={OPEN_WEATHER_PRECIP_LAYER_ID} sourceId={OPEN_WEATHER_PRECIP_SOURCE_ID} layout={{ visibility: showPrecipitationLayer ? 'visible' : 'none' }} />
    </React.Fragment>
  );
}
