import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import store from './app/store'

import AppContainer from './app/App.jsx'

import * as serviceWorker from './serviceWorker'

import './index.css'
import 'react-selectize/dist/index.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

if (!!window.MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(window.MIXPANEL_PROJECT_TOKEN)
}

const container = document.getElementById('app')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
)

window.Gisual = {
  version: process.env.REACT_APP_VERSION,
  environment_name: window.GISUAL_ENVIRONMENT,
  api_url: window.GISUAL_API_URL,
  maintenance_mode_status: window.GISUAL_MAINTENANCE_MODE,
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
