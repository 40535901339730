import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ListGroup } from 'react-bootstrap'
import { Link, SideSheet } from 'evergreen-ui'
import { MapContext } from 'react-mapbox-gl'
import MapLegend, {
  LegendSection,
  LegendItem as MiniLegendItem,
} from '../../../../common/MapLegend/MapLegend.jsx'

import {
  POINT_NODES_LAYER_ID,
  POLYGON_NODES_LAYER_ID,
  NODE_COLOR,
  NETWORK_LINES_LAYER_ID,
  NETWORK_LINES_COLOR,
  DATA_CENTERS_LAYER_ID,
  DATA_CENTERS_COLOR,
  OTHER_ASSETS_LAYER_ID,
  OTHER_ASSETS_COLOR,
  CRITICAL_NODE_COLOR,
} from './AssetsLayer.jsx'
import {
  INCIDENT_COLOR_POWER_OUT,
  INCIDENT_COLOR_RESOLVED,
  INCIDENT_COLOR_VALIDATING_POWER,
} from './IncidentsLayer.jsx'
import {
  INCIDENT_OUTAGE_CLUSTERS_LAYER_ID,
  NEARBY_OUTAGE_CLUSTERS_LAYER_ID,
  INCIDENT_OUTAGE_CLUSTERS_TEXT_LAYER_ID,
  NEARBY_OUTAGE_CLUSTERS_TEXT_LAYER_ID,
  INCIDENT_OUTAGE_POLYGONS_LAYER_ID,
  NEARBY_OUTAGE_POLYGONS_LAYER_ID,
  INCIDENT_OUTAGE_COLOR,
  NEARBY_OUTAGE_COLOR,
} from './OutagesLayer.jsx'
import { NODE_RELATIONSHIP_LINES_LAYER_ID } from './RelationshipLinesLayer.jsx'
import { OPEN_WEATHER_PRECIP_LAYER_ID } from './WeatherDataLayers.jsx'

import styles from '../Map.module.scss'
import {
  fetchItemsFromLocalStorage,
  saveItemsToLocalStorage,
} from '../../../../helpers/localStore.js'
import {
  POWER_SUPPLIES_LAYER_ID,
  POWER_SUPPLY_COLOR,
  POWER_SUPPLY_LOW_FUEL_COLOR,
  POWER_SUPPLY_WITH_GENERATOR_COLOR,
} from './PowerSuppliesLayer.jsx'

export const LayerFilterContext = createContext()

const layerVisibilityLSPrefix = 'layer-visibility'

const getVisibilityFromLocalStorage = () => {
  const defaultVisibility = {
    showIncidentOutages: true,
    showNearbyOutages: true,
    showNodes: true,
    showNetworkLines: false,
    showDataCenters: false,
    showOtherAssets: false,
    showPowerSupplies: false,
    showPrecipitationLayer: false,
  }

  const savedVisibility = fetchItemsFromLocalStorage(
    Object.keys(defaultVisibility),
    layerVisibilityLSPrefix,
  )

  Object.entries(savedVisibility).forEach(([label, value]) => {
    if (value !== null) {
      defaultVisibility[label] = value
    }
  })

  return defaultVisibility
}

export function LayerFilterProvider({ children }) {
  const savedVisibility = getVisibilityFromLocalStorage()

  const [showFilterMenu, setShowFilterMenu] = useState(false)
  const [showIncidentOutages, setShowIncidentOutages] = useState(true)
  const [showNearbyOutages, setShowNearbyOutages] = useState(
    savedVisibility.showNearbyOutages,
  )
  const [showNodes, setShowNodes] = useState(savedVisibility.showNodes)
  const [showNetworkLines, setShowNetworkLines] = useState(
    savedVisibility.showNetworkLines,
  )
  const [showDataCenters, setShowDataCenters] = useState(
    savedVisibility.showDataCenters,
  )
  const [showOtherAssets, setShowOtherAssets] = useState(
    savedVisibility.showOtherAssets,
  )
  const [showPowerSupplies, setShowPowerSupplies] = useState(
    savedVisibility.showPowerSupplies,
  )
  const [showPrecipitationLayer, setShowPrecipitationLayer] = useState(
    savedVisibility.showPrecipitationLayer,
  )

  const fullContext = {
    showFilterMenu,
    setShowFilterMenu,
    showIncidentOutages,
    setShowIncidentOutages,
    showNearbyOutages,
    setShowNearbyOutages,
    showNodes,
    setShowNodes,
    showNetworkLines,
    setShowNetworkLines,
    showDataCenters,
    setShowDataCenters,
    showOtherAssets,
    setShowOtherAssets,
    showPowerSupplies,
    setShowPowerSupplies,
    showPrecipitationLayer,
    setShowPrecipitationLayer,
  }

  return (
    <LayerFilterContext.Provider value={fullContext}>
      {children}
    </LayerFilterContext.Provider>
  )
}

export function LayerFilterController(props) {
  const map = useContext(MapContext)

  const {
    showIncidentOutages,
    showNearbyOutages,
    showNodes,
    showNetworkLines,
    showDataCenters,
    showOtherAssets,
    showPowerSupplies,
    showPrecipitationLayer,
  } = useContext(LayerFilterContext)

  // Get the layers here so that useEffects can be re-run when the layers change
  const incidentOutagesLayer = map.getLayer(INCIDENT_OUTAGE_CLUSTERS_LAYER_ID)
  const nearbyOutagesLayer = map.getLayer(NEARBY_OUTAGE_CLUSTERS_LAYER_ID)
  const pointNodesLayer = map.getLayer(POINT_NODES_LAYER_ID)
  const nodeRelationshipLinesLayer = map.getLayer(
    NODE_RELATIONSHIP_LINES_LAYER_ID,
  )
  const polygonNodesLayer = map.getLayer(POLYGON_NODES_LAYER_ID)
  const networkLinesLayer = map.getLayer(NETWORK_LINES_LAYER_ID)
  const dataCentersLayer = map.getLayer(DATA_CENTERS_LAYER_ID)
  const otherAssetsLayer = map.getLayer(OTHER_ASSETS_LAYER_ID)
  const powerSuppliesLayer = map.getLayer(POWER_SUPPLIES_LAYER_ID)
  const precipitationLayer = map.getLayer(OPEN_WEATHER_PRECIP_LAYER_ID)

  const changeWeatherLayersVisibility = useCallback(
    (weatherLayerId, layer, label, showLayer) => {
      saveItemsToLocalStorage({ [label]: showLayer }, layerVisibilityLSPrefix)
    },
    [],
  )

  // Change incident outages visibility
  useEffect(() => {
    saveItemsToLocalStorage({ showIncidentOutages }, layerVisibilityLSPrefix)

    if (!incidentOutagesLayer) return

    const filter = showIncidentOutages ? null : ['boolean', false]

    map.setFilter(INCIDENT_OUTAGE_POLYGONS_LAYER_ID, filter)
    map.setFilter(INCIDENT_OUTAGE_CLUSTERS_LAYER_ID, filter)
    map.setFilter(INCIDENT_OUTAGE_CLUSTERS_TEXT_LAYER_ID, filter)
  }, [showIncidentOutages, incidentOutagesLayer, map])

  // Change territory outages visibility
  useEffect(() => {
    saveItemsToLocalStorage({ showNearbyOutages }, layerVisibilityLSPrefix)

    if (!nearbyOutagesLayer) return

    const filter = showNearbyOutages ? null : ['boolean', false]

    map.setFilter(NEARBY_OUTAGE_POLYGONS_LAYER_ID, filter)
    map.setFilter(NEARBY_OUTAGE_CLUSTERS_LAYER_ID, filter)
    map.setFilter(NEARBY_OUTAGE_CLUSTERS_TEXT_LAYER_ID, filter)
  }, [showNearbyOutages, nearbyOutagesLayer, map])

  // Change nodes visibility
  useEffect(() => {
    saveItemsToLocalStorage({ showNodes }, layerVisibilityLSPrefix)

    const visibility = showNodes ? 'visible' : 'none'

    if (pointNodesLayer) {
      map.setLayoutProperty(POINT_NODES_LAYER_ID, 'visibility', visibility)
    }

    if (polygonNodesLayer) {
      map.setLayoutProperty(POLYGON_NODES_LAYER_ID, 'visibility', visibility)
    }

    if (nodeRelationshipLinesLayer) {
      map.setLayoutProperty(
        NODE_RELATIONSHIP_LINES_LAYER_ID,
        'visibility',
        visibility,
      )
    }
  }, [
    showNodes,
    pointNodesLayer,
    polygonNodesLayer,
    nodeRelationshipLinesLayer,
    map,
  ])

  // Change network lines visibility
  useEffect(() => {
    saveItemsToLocalStorage({ showNetworkLines }, layerVisibilityLSPrefix)

    if (networkLinesLayer) {
      const visibility = showNetworkLines ? 'visible' : 'none'

      map.setLayoutProperty(NETWORK_LINES_LAYER_ID, 'visibility', visibility)
    }
  }, [showNetworkLines, networkLinesLayer, map])

  // Change data centers visibility
  useEffect(() => {
    saveItemsToLocalStorage({ showDataCenters }, layerVisibilityLSPrefix)

    if (dataCentersLayer) {
      const visibility = showDataCenters ? 'visible' : 'none'

      map.setLayoutProperty(DATA_CENTERS_LAYER_ID, 'visibility', visibility)
    }
  }, [showDataCenters, dataCentersLayer, map])

  useEffect(() => {
    saveItemsToLocalStorage({ showOtherAssets }, layerVisibilityLSPrefix)

    if (otherAssetsLayer) {
      const visibility = showOtherAssets ? 'visible' : 'none'

      map.setLayoutProperty(OTHER_ASSETS_LAYER_ID, 'visibility', visibility)
    }
  }, [showOtherAssets, otherAssetsLayer, map])

  useEffect(() => {
    saveItemsToLocalStorage({ showPowerSupplies }, layerVisibilityLSPrefix)

    if (powerSuppliesLayer) {
      const visibility = showPowerSupplies ? 'visible' : 'none'

      map.setLayoutProperty(POWER_SUPPLIES_LAYER_ID, 'visibility', visibility)
    }
  }, [showPowerSupplies, powerSuppliesLayer, map])

  useEffect(
    () =>
      changeWeatherLayersVisibility(
        OPEN_WEATHER_PRECIP_LAYER_ID,
        precipitationLayer,
        'showPrecipitationLayer',
        showPrecipitationLayer,
      ),
    [changeWeatherLayersVisibility, precipitationLayer, showPrecipitationLayer],
  )

  return null
}

const MiniLegendSectionItems = ({ layers }) => {
  return layers
    .filter((layer) => layer.keepInLegend || layer.selected)
    .map((layer) => (
      <MiniLegendItem
        items={layer.items}
        selected={layer.selected}
        onToggle={layer.handler}
        key={Object.keys(layer.items)[0]}
      />
    ))
}

const MiniLegend = ({ incidentLayers, otherLayers, t }) => {
  const { setShowFilterMenu } = useContext(LayerFilterContext)

  return (
    <MapLegend offsetBottom={40}>
      <LegendSection title={t('incidents')}>
        <MiniLegendSectionItems layers={incidentLayers} />
      </LegendSection>
      <LegendSection title={t('other_layers')}>
        <MiniLegendSectionItems layers={otherLayers} />
      </LegendSection>
      <Link
        className={styles.legendLink}
        size={300}
        marginLeft="auto"
        marginRight="auto"
        onClick={() => setShowFilterMenu(true)}
        href="#"
      >
        {t('access_more_layers')}
      </Link>
    </MapLegend>
  )
}

const LegendItem = ({ items, selected = true, handler = null }) => (
  <ListGroup.Item className={styles.layerItem}>
    <div className={styles.legendDoubleLabel}>
      {Object.entries(items).map(([label, color]) => (
        <div key={label}>
          <span className={styles.legendSymbol}>
            <span
              className={styles.legendDot}
              style={{ backgroundColor: color }}
            ></span>
          </span>
          <span>{label}</span>
        </div>
      ))}
    </div>
    <input
      type="checkbox"
      checked={selected}
      disabled={!handler}
      className={handler ? undefined : styles.hiddenCheckbox}
      onChange={() => handler(!selected)}
    />
  </ListGroup.Item>
)

export default function Legend(props) {
  const {
    showFilterMenu,
    setShowFilterMenu,
    showIncidentOutages,
    setShowIncidentOutages,
    showNearbyOutages,
    setShowNearbyOutages,
    showNodes,
    setShowNodes,
    showNetworkLines,
    setShowNetworkLines,
    showDataCenters,
    setShowDataCenters,
    showOtherAssets,
    setShowOtherAssets,
    showPowerSupplies,
    setShowPowerSupplies,
    showPrecipitationLayer,
    setShowPrecipitationLayer,
  } = useContext(LayerFilterContext)

  const handleClose = () => setShowFilterMenu(false)
  const { t } = useTranslation('maps')

  const incidentLayers = [
    {
      items: { [t('validating_power')]: INCIDENT_COLOR_VALIDATING_POWER },
      keepInLegend: true,
    },
    {
      items: { [t('power_out')]: INCIDENT_COLOR_POWER_OUT },
      keepInLegend: true,
    },
    {
      items: { [t('resolved')]: INCIDENT_COLOR_RESOLVED },
      keepInLegend: true,
    },
  ]

  const assetLayers = [
    {
      items: {
        [t('nodes')]: NODE_COLOR,
        [t('critical_nodes')]: CRITICAL_NODE_COLOR,
      },
      selected: showNodes,
      handler: setShowNodes,
      keepInLegend: true,
    },
    {
      items: { [t('network_lines')]: NETWORK_LINES_COLOR },
      selected: showNetworkLines,
      handler: setShowNetworkLines,
    },
    {
      items: { [t('data_centers')]: DATA_CENTERS_COLOR },
      selected: showDataCenters,
      handler: setShowDataCenters,
    },
    {
      items: { [t('other_assets')]: OTHER_ASSETS_COLOR },
      selected: showOtherAssets,
      handler: setShowOtherAssets,
    },
    {
      items: {
        [t('power_supplies')]: POWER_SUPPLY_COLOR,
        [t('generators')]: POWER_SUPPLY_WITH_GENERATOR_COLOR,
        [t('generators_low_fuel')]: POWER_SUPPLY_LOW_FUEL_COLOR,
      },
      selected: showPowerSupplies,
      handler: setShowPowerSupplies,
    },
  ]

  const outageLayers = [
    {
      items: { [t('impacting_outages')]: INCIDENT_OUTAGE_COLOR },
      keepInLegend: true,
    },
    {
      items: { [t('nearby_outages')]: NEARBY_OUTAGE_COLOR },
      selected: showNearbyOutages,
      handler: setShowNearbyOutages,
      keepInLegend: true,
    },
  ]

  const dataLayers = [
    {
      items: { [t('environmentals')]: 'transparent' },
      selected: showPrecipitationLayer,
      handler: setShowPrecipitationLayer,
    },
  ]

  return (
    <>
      <MiniLegend
        incidentLayers={incidentLayers}
        otherLayers={[...outageLayers, ...assetLayers, ...dataLayers]}
        t={t}
      />
      <SideSheet
        isShown={showFilterMenu}
        width={300}
        containerProps={{
          paddingX: 15,
          paddingY: 10,
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        }}
        onCloseComplete={handleClose}
      >
        <h3 className={styles.partnerTitle}>{t('map_layers')}</h3>
        <ListGroup>
          <h5>{t('incidents')}</h5>
          {incidentLayers.map((layer, i) => (
            <LegendItem {...layer} key={i} />
          ))}
          <br />

          <h5>{t('assets')}</h5>
          {assetLayers.map((layer, i) => (
            <LegendItem {...layer} key={i} />
          ))}
          <br />

          <h5>{t('data')}</h5>
          {outageLayers.map((layer, i) => (
            <LegendItem {...layer} key={i} />
          ))}
          {dataLayers.map((layer, i) => (
            <LegendItem {...layer} key={i} />
          ))}
        </ListGroup>
      </SideSheet>
    </>
  )
}
