// Styles to allow users to right-click paste into the address bar

const addressBarStyles = {
  input: (provided, state) => ({
    ...provided,
    width: '100% !important',
  })
}

export default addressBarStyles
