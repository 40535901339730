import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Layer, MapContext, Image } from "react-mapbox-gl"

import outagePattern from '../../../../assets/outage_pattern.png';

import PopupContext from './PopupContext'
import { useSelector } from 'react-redux';
import { getOutagesInServiceTerritoryAsGeoJSON } from '../../../../api/intel.js';
import circleToPolygon from 'circle-to-polygon';
import { DateTime } from 'luxon';
import { selectOutages } from '../../../store/IncidentSlice';

const OutagePopup = React.lazy(() => import('./OutagePopup'))

export const INCIDENT_OUTAGE_CLUSTERS_LAYER_ID = "incident-outages-cluster-layer";
export const NEARBY_OUTAGE_CLUSTERS_LAYER_ID = "nearby-outages-cluster-layer";
export const INCIDENT_OUTAGE_CLUSTERS_TEXT_LAYER_ID = "incident-outages-cluster-count-layer";
export const NEARBY_OUTAGE_CLUSTERS_TEXT_LAYER_ID = "nearby-outages-cluster-count-layer";
export const INCIDENT_OUTAGE_POLYGONS_LAYER_ID = "incident-outage-polygons-layer";
export const NEARBY_OUTAGE_POLYGONS_LAYER_ID = "nearby-outage-polygons-layer";
export const INCIDENT_OUTAGE_COLOR = "#FF932A";
export const INCIDENT_OUTAGE_TEXT_COLOR = "#FFFFFF";
export const NEARBY_OUTAGE_COLOR = "#FFD6AD";
export const NEARBY_OUTAGE_TEXT_COLOR = "#000000";

const INCIDENT_OUTAGE_POINTS_DATA_SOURCE_ID = "incident-outage-data-points-source";
const NEARBY_OUTAGE_POINTS_DATA_SOURCE_ID = "nearby-outage-data-points-source";
const INCIDENT_OUTAGE_POLYGONS_DATA_SOURCE_ID = "incident-outage-polygons-source";
const NEARBY_OUTAGE_POLYGONS_DATA_SOURCE_ID = "nearby-outage-polygons-source";

const PartialOutagesLayer = ({
  clustersLayerId,
  clustersTextLayerId,
  polygonsLayerId,
  pointsSourceId,
  polygonsSourceId,
  pointColor,
  textColor,
  outagePoints,
  outagePolygons,
  handleOpenPopup,
}) => {
  const map = useContext(MapContext);

  const [sourceLoaded, setSourceLoaded] = useState(false)

  const updateLayer = useCallback((dataSourceId, data) => {
    try {
      map.getSource(dataSourceId).setData({
        type: "FeatureCollection",
        features: data
      })

      setSourceLoaded(true)
    } catch {}
  }, [map])

  // Set up map data sources
  useEffect(() => {
    const emptyCollection = {
      type: "FeatureCollection",
      features: []
    }

    map.addSource(pointsSourceId, {
      type: "geojson",
      data: emptyCollection,
      cluster: true,
      clusterMaxZoom: 11,
      clusterRadius: 40
    })

    map.addSource(polygonsSourceId, {
      type: "geojson",
      data: emptyCollection
    })
  }, [map, pointsSourceId, polygonsSourceId])

  // Update layers when outage info is loaded
  useEffect(() => {
    updateLayer(pointsSourceId, outagePoints)
  }, [outagePoints, pointsSourceId, updateLayer])

  useEffect(() => {
    updateLayer(polygonsSourceId, outagePolygons)
  }, [outagePolygons, polygonsSourceId, updateLayer])

  return sourceLoaded && (
    <React.Fragment>
      <Layer
        id={polygonsLayerId}
        sourceId={polygonsSourceId}
        type="fill"
        filter={['!', ['has', 'cluster']]}
        paint={{
          "fill-opacity": 0.15,
          "fill-color": "#FF6700",
          "fill-pattern": "outage-pattern"
        }}
        minZoom={12}
      />
      <Layer
        id={clustersLayerId}
        sourceId={pointsSourceId}
        type="circle"
        paint={{
          "circle-color": pointColor,
          "circle-radius": 14,
        }}
        onClick={(e) => {
          const featureProps = e.features[0].properties
          const zoomLvl = e.target.transform.tileZoom

          if (!featureProps.cluster) {
            if (zoomLvl < 8) {
              map.flyTo({
                center: e.lngLat,
                zoom: zoomLvl + 2,
                speed: 2,
              })
            }

            handleOpenPopup("outage", {
              ...(featureProps),
              coordinates: e.lngLat,
            })
          } else if (featureProps.cluster) {
            map.flyTo({
              center: e.lngLat,
              zoom: zoomLvl + 2,
              speed: 2,
            })
          }
        }}
      />
      <Layer
        id={clustersTextLayerId}
        sourceId={pointsSourceId}
        type="symbol"
        layout={{
          'text-field': ["get", "point_count"],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        }}
        paint={{
          'text-color': textColor
        }}
      />
    </React.Fragment>
  )
}

export default function OutagesLayer() {
  const {
    currentPopupType,
    popupData,
    handleOpenPopup,
    handleClosePopup
  } = useContext(PopupContext);

  const incidentOutages = useSelector(selectOutages)
  const [nearbyOutagesByUtility, setNearbyOutagesByUtility] = useState({})

  const incidentOutagePoints = useMemo(() => {
    return Object.values(incidentOutages).map((outage) => ({
      type: 'Feature',
      geometry: outage.location.geojson,
      properties: outage
    }))
  }, [incidentOutages])

  const incidentOutagePolygons = useMemo(() => {
    return Object.values(incidentOutages).map((outage) => ({
      type: 'Feature',
      geometry: outage.impact_polygon,
      properties: outage
    }))
  }, [incidentOutages])

  const nearbyOutagePoints = useMemo(() => {
    const renderedIds = new Set(
      Object.values(incidentOutages).map((outage) => outage.metadata.id)
    )

    return Object.values(nearbyOutagesByUtility)
      .map(({ outages }) => outages)
      .flat()
      .filter((outage) => !renderedIds.has(outage.properties.metadata.id))
  }, [nearbyOutagesByUtility, incidentOutages])

  const nearbyOutagePolygons = useMemo(() => {
    const outagePolygons = nearbyOutagePoints.map((outage) => {
      if (outage.properties.metadata.display_impact_polygon) {
        return {
          type: 'Feature',
          geometry: outage.properties.metadata.display_impact_polygon,
          properties: outage.properties,
        }
      }

      const coordinates = outage.geometry.coordinates
      const radiusMiles = outage.properties.metadata.impact_radius
      const radiusMeters = radiusMiles * 1609.34

      const polygon = circleToPolygon(coordinates, radiusMeters)

      return {
        type: 'Feature',
        geometry: polygon,
        properties: outage.properties,
      }
    })

    return outagePolygons
  }, [nearbyOutagePoints])

  const loadNearbyOutages = useCallback(async () => {
    const response = await getOutagesInServiceTerritoryAsGeoJSON()
    const nearbyOutages = response.data

    if (!nearbyOutages) {
      return
    }

    const receivedOutagesByUtility = {}

    nearbyOutages.features.forEach(
      (outage) => {
        const outageMetadata = outage.properties.metadata
        outage.properties = {
          incident_name: outageMetadata.organization_short_name,
          incident_summary: "Power outage",
          incident_priority_name: outageMetadata.severity,
          incident_status_name: outage.properties.status,
          incident_etr: outageMetadata.etr,
          organization_name: outageMetadata.organization_name,
          metadata: outageMetadata,
        }

        const utility = outageMetadata.name

        if (!receivedOutagesByUtility[utility]) {
          receivedOutagesByUtility[utility] = {
            fetchTime: DateTime.now(),
            outages: []
          }
        }

        receivedOutagesByUtility[utility].outages.push(outage)
      }
    )

    setNearbyOutagesByUtility((previousOutagesByUtility) => {
      const newOutagesByUtility = {
        ...receivedOutagesByUtility
      }

      Object.entries(previousOutagesByUtility).forEach(([utility, { fetchTime, outages }]) => {
        const now = DateTime.now()
        const cacheValid = fetchTime.plus({ hours: 1 }) > now

        if (!newOutagesByUtility[utility] && cacheValid) {
          newOutagesByUtility[utility] = {
            fetchTime,
            outages: outages.filter((outage) => {
              const etr = outage.properties.etr

              if (!etr) {
                return true
              }

              const parsedEtr = DateTime.fromISO(etr)
              return parsedEtr > now
            })
          }
        }
      })

      return newOutagesByUtility
    })
  }, [])

  // Load other outages in the customer service territory
  useEffect(() => {
    // Initial data fetch
    loadNearbyOutages()

    // Set an interval to update outage data every 60 seconds
    const interval = setInterval(loadNearbyOutages, 60000)

    // Clear the interval on cleanup
    return () => clearInterval(interval)
  }, [loadNearbyOutages])

  return (
    <React.Fragment>
      <Image id='outage-pattern' url={outagePattern} />
      <PartialOutagesLayer
        clustersLayerId={NEARBY_OUTAGE_CLUSTERS_LAYER_ID}
        clustersTextLayerId={NEARBY_OUTAGE_CLUSTERS_TEXT_LAYER_ID}
        polygonsLayerId={NEARBY_OUTAGE_POLYGONS_LAYER_ID}
        pointsSourceId={NEARBY_OUTAGE_POINTS_DATA_SOURCE_ID}
        polygonsSourceId={NEARBY_OUTAGE_POLYGONS_DATA_SOURCE_ID}
        pointColor={NEARBY_OUTAGE_COLOR}
        textColor={NEARBY_OUTAGE_TEXT_COLOR}
        outagePoints={nearbyOutagePoints}
        outagePolygons={nearbyOutagePolygons}
        handleOpenPopup={handleOpenPopup}
      />
      <PartialOutagesLayer
        clustersLayerId={INCIDENT_OUTAGE_CLUSTERS_LAYER_ID}
        clustersTextLayerId={INCIDENT_OUTAGE_CLUSTERS_TEXT_LAYER_ID}
        polygonsLayerId={INCIDENT_OUTAGE_POLYGONS_LAYER_ID}
        pointsSourceId={INCIDENT_OUTAGE_POINTS_DATA_SOURCE_ID}
        polygonsSourceId={INCIDENT_OUTAGE_POLYGONS_DATA_SOURCE_ID}
        pointColor={INCIDENT_OUTAGE_COLOR}
        textColor={INCIDENT_OUTAGE_TEXT_COLOR}
        outagePoints={incidentOutagePoints}
        outagePolygons={incidentOutagePolygons}
        handleOpenPopup={handleOpenPopup}
      />
      <Suspense fallback={null}>
        {currentPopupType === "outage" && <OutagePopup {...{ popupData, handleClosePopup }} />}
      </Suspense>
    </React.Fragment>
  );
}
