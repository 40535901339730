import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  regionalPowerOutages: null,
  regionalNetworkOutages: {},
}

const OverviewSlice = createSlice({
  name: 'overview',
  slice: 'overview',
  initialState: initialState,
  reducers: {
    setRegionalPowerOutages(state, action) {
      state.regionalPowerOutages = action.payload
    },
    setRegionalNetworkOutages(state, action) {
      state.regionalNetworkOutages = action.payload
    },
  },
})

export const {
  setRegionalPowerOutages,
  setRegionalNetworkOutages,
} = OverviewSlice.actions

export default OverviewSlice.reducer
