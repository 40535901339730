import React, { useCallback, useRef } from 'react'
import ReactMapboxGl from 'react-mapbox-gl'
import { API_URL } from '../api/client'
import 'mapbox-gl/dist/mapbox-gl.css'

const MapGl = ReactMapboxGl({
  accessToken: window.MAPBOX_API_KEY,
  attributionControl: true,
  noWrap: true,
  transformRequest: (url, resourceType) => {
    if (resourceType === 'Tile' && url.startsWith(API_URL)) {
      return {
        url: url,
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      }
    }
  },
})

const MapboxMap = ({
                     children,
                     mapStyle,
                     containerStyle = { flex: 1 },
                     onMapMove,
                     onMapMoveEnd,
                     mapMoveTimeout = 1000,
                     ...props
                   }) => {
  const timeoutRef = useRef(null);

  const handleMove = useCallback((map) => {
    const center = map.getCenter();
    const zoom = map.getZoom();
    const bearing = map.getBearing();
    const pitch = map.getPitch();

    const mapState = {
      lng: center.lng,
      lat: center.lat,
      zoom,
      bearing,
      pitch
    };

    // Call the onMapMove prop with the new map state
    if (onMapMove) {
      onMapMove(mapState);
    }

    // Clear the existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      if (onMapMoveEnd) {
        onMapMoveEnd(mapState);
      }
    }, mapMoveTimeout);
  }, [onMapMove, onMapMoveEnd, mapMoveTimeout]);

  return (
    <MapGl
      style={mapStyle}
      containerStyle={containerStyle}
      onMove={handleMove}
      {...props}
    >
      {children}
    </MapGl>
  )
}

export default MapboxMap