import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Layer, MapContext } from 'react-mapbox-gl'
import { toaster } from 'evergreen-ui'

import PopupContext from './PopupContext'

import { addUtility } from '../../../store/OrganizationSlice'
import { setTerritories, setNetworkLines } from '../../../store/AssetSlice'
import {
  updateAsset,
  deleteAsset,
  getAllAssetsByType,
  getOtherAssets,
} from '../../../../api/assets.js'
import { API_URL } from '../../../../api/client'

import { recordAnalyticEvent } from '../../../../api/analytics'

import { getGlobalUserOrganizationId } from '../../../../api/aaa'

const AssetPopup = React.lazy(() => import('./AssetPopup'))
const AssetEditPanel = React.lazy(() => import('./AssetEditPanel'))

export const TERRITORIES_LAYER_ID = 'asset-territories-layer'
export const POINT_NODES_LAYER_ID = 'asset-point-nodes-layer'
export const POLYGON_NODES_LAYER_ID = 'asset-polygon-nodes-layer'
export const NETWORK_LINES_LAYER_ID = 'asset-network-lines-layer'
export const DATA_CENTERS_LAYER_ID = 'asset-data-centers-layer'
export const OTHER_ASSETS_LAYER_ID = 'asset-other-layer'

const TERRITORIES_SOURCE_ID = 'territories-data-source'
const NODES_SOURCE_ID = 'nodes-data-source'
const NETWORK_LINES_SOURCE_ID = 'network-lines-data-source'
const DATA_CENTERS_SOURCE_ID = 'asset-data-centers-source'
const OTHER_ASSETS_SOURCE_ID = 'asset-other-source'

export const NODE_COLOR = '#AB47BC'
export const CRITICAL_NODE_COLOR = '#304FFE'
export const NETWORK_LINES_COLOR = '#69ADDC'
export const NODE_AFFECTED_COLOR = '#FF3232'
export const DATA_CENTERS_COLOR = '#546E7A'
export const OTHER_ASSETS_COLOR = '#40A4FC'

const assetLayerZoomLevelMin = 3

const mapState = (state) => ({
  serviceTerritories: state.assets.serviceTerritories,
  networkLines: state.assets.networkLines,
  utilOrgs: state.organizations.utilities,
  incidents: state.incidents.geojson,
})
const mapDispatch = { setTerritories, setNetworkLines, addUtility }

function AssetsLayer(props) {
  const { incidents, serviceTerritories } = props

  const map = useContext(MapContext)
  const { currentPopupType, popupData, handleClosePopup } =
    useContext(PopupContext)

  const [sourcesLoaded, setSourcesLoaded] = useState(false)
  const [toggleEdit, setToggleEdit] = useState(false)
  const [assetEditFocus, setAssetFocus] = useState({})
  const [affectedNodeInfo, setAffectedNodeInfo] = useState([])

  // useSuspense needs to be set to false in map layers or it will create
  // a race condition, as loading order affects rendering order.
  const { t } = useTranslation('maps', { useSuspense: false })

  const pointNodesStyleCondition = useMemo(() => {
    const styleCondition = ['case']

    affectedNodeInfo.forEach(({ id }) => {
      styleCondition.push(
        // asset_ids is a JSON array, but mapbox sees it as a string
        // This checks if id is contained in the asset_ids string
        ['in', id, ['get', 'asset_ids']],
        NODE_AFFECTED_COLOR,
      )
    })

    styleCondition.push(['==', ['get', 'is_critical_node'], true])
    styleCondition.push(CRITICAL_NODE_COLOR)
    styleCondition.push(NODE_COLOR)

    return styleCondition
  }, [affectedNodeInfo])

  const affectedNodeIds = useMemo(
    () => affectedNodeInfo.map(({ id }) => id),
    [affectedNodeInfo],
  )

  /*
    Initial Page Load effect:
      - Create data layers for territories, nodes.

  */
  useEffect(() => {
    const emptyGeojson = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    }

    // Add layer sources (the order here determines the map drawing order)
    map.addSource(TERRITORIES_SOURCE_ID, emptyGeojson)
    map.addSource(NODES_SOURCE_ID, {
      type: 'vector',
      tiles: [`${API_URL}/assets/tiles/{z}/{x}/{y}`],
      tolerance: 0,
    })
    map.addSource(NETWORK_LINES_SOURCE_ID, emptyGeojson)
    map.addSource(DATA_CENTERS_SOURCE_ID, emptyGeojson)
    map.addSource(OTHER_ASSETS_SOURCE_ID, emptyGeojson)

    // Fetch other assets info and update the layer source
    async function loadOtherAssets() {
      // Fetch & Load Data Centers
      const dataCenters = await getAllAssetsByType('data center')
      if (!!dataCenters) {
        map.getSource(DATA_CENTERS_SOURCE_ID).setData(dataCenters)
      }

      // Fetch & Load Other Assets
      const otherAssets = await getOtherAssets()
      if (!!otherAssets) {
        map.getSource(OTHER_ASSETS_SOURCE_ID).setData(otherAssets)
      }
    }

    loadOtherAssets()
    setSourcesLoaded(true)
  }, [map])

  useEffect(() => {
    if (incidents?.features) {
      const affectedNodes = []

      incidents.features.forEach((incident) => {
        if (incident.properties.incident_status_name === 'resolved') {
          return
        }

        incident.properties.dependents.forEach((dependent) => {
          if (dependent.record_type === 'assets') {
            affectedNodes.push({
              id: dependent.record_id,
              incident: incident,
            })
          }
        })
      })

      setAffectedNodeInfo(affectedNodes)
    }
  }, [incidents])

  const updateNodeHandler = (op, assetId, data = {}, appliedUpdates = {}) => {
    if (op === 'edit') {
      updateAsset(assetId, data)
        .then(() => {
          // setRenderFlag(false)
          setToggleEdit(false) // Close the panel
          setAssetFocus({})
          window.location.reload()
        })
        .catch(() => {
          toaster.warning(t('asset_update_error'), { duration: 5 })
        })
    } else if (op === 'delete') {
      deleteAsset(assetId)
        .then(() => {
          setToggleEdit(false) // Close the panel
          setAssetFocus({})
        })
        .catch(() => {
          toaster.warning(t('asset_delete_error'), { duration: 5 })
        })
    }
  }

  /*
    Convert Territories from raw data to features, trigger after the initial fetch finishes or on map load.
  */
  useEffect(() => {
    try {
      map.getSource(TERRITORIES_SOURCE_ID).setData(serviceTerritories)
    } catch {}
  }, [serviceTerritories, map])

  /*
    Convert Network Lines from raw data to features, trigger after the initial fetch for network lines finishes.
  */
  useEffect(() => {
    try {
      map.getSource(NETWORK_LINES_SOURCE_ID).setData(props.networkLines)
    } catch {}
  }, [props.networkLines, map])

  return (
    <React.Fragment>
      {sourcesLoaded && (
        <React.Fragment>
          <Layer
            id={TERRITORIES_LAYER_ID}
            sourceId={TERRITORIES_SOURCE_ID}
            type="fill"
            paint={{
              'fill-opacity': 0.15,
            }}
          />

          <Layer
            id={POINT_NODES_LAYER_ID}
            sourceId={NODES_SOURCE_ID}
            sourceLayer={`points-${getGlobalUserOrganizationId()}`}
            type="circle"
            paint={{
              'circle-color': pointNodesStyleCondition,
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                6,
                2,
                14,
                8,
              ],
            }}
            minZoom={assetLayerZoomLevelMin}
          />
          <Layer
            id={POLYGON_NODES_LAYER_ID}
            sourceId={NODES_SOURCE_ID}
            sourceLayer={`polygons-${getGlobalUserOrganizationId()}`}
            type="fill"
            paint={{
              'fill-color': [
                'case',
                ['in', ['get', 'asset_id'], ['literal', affectedNodeIds]],
                NODE_AFFECTED_COLOR,
                ['==', ['get', 'is_critical_node'], true],
                CRITICAL_NODE_COLOR,
                NODE_COLOR,
              ],
              'fill-opacity': 0.4,
            }}
            minZoom={assetLayerZoomLevelMin}
          />

          <Layer
            id={NETWORK_LINES_LAYER_ID}
            type="line"
            sourceId={NETWORK_LINES_SOURCE_ID}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
            paint={{
              'line-width': 4,
              'line-color': NETWORK_LINES_COLOR,
            }}
            minZoom={assetLayerZoomLevelMin}
          />

          <Layer
            id={DATA_CENTERS_LAYER_ID}
            sourceId={DATA_CENTERS_SOURCE_ID}
            type="circle"
            paint={{
              'circle-color': DATA_CENTERS_COLOR,
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                6,
                2,
                14,
                8,
              ],
            }}
            minZoom={assetLayerZoomLevelMin}
          />

          <Layer
            id={OTHER_ASSETS_LAYER_ID}
            sourceId={OTHER_ASSETS_SOURCE_ID}
            type="circle"
            paint={{
              'circle-color': OTHER_ASSETS_COLOR,
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                6,
                2,
                14,
                8,
              ],
            }}
            minZoom={assetLayerZoomLevelMin}
          />
        </React.Fragment>
      )}

      <Suspense fallback={null}>
        {currentPopupType === 'asset' && (
          <AssetPopup
            {...{ utilOrgs: props.utilOrgs, addUtility: props.addUtility }}
            toggleAssetEditor={(asset) => {
              if (!toggleEdit) {
                recordAnalyticEvent('Open Asset Editor Panel on Map')
                setAssetFocus(asset)
              } else {
                recordAnalyticEvent('Close Asset Editor Panel on Map')
                setAssetFocus({})
              }

              setToggleEdit(!toggleEdit)
            }}
            {...{ popupData, handleClosePopup }}
          />
        )}
      </Suspense>

      <Suspense fallback={null}>
        <AssetEditPanel
          {...{ toggleEdit, setToggleEdit, updateNodeHandler, assetEditFocus }}
        />
      </Suspense>
    </React.Fragment>
  )
}

export default connect(mapState, mapDispatch)(AssetsLayer)
