import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GeoJSONLayer, Image } from 'react-mapbox-gl'
import { useTranslation } from 'react-i18next'

import IncidentSheet from '../../../../common/incident-side-sheet/IncidentSheetComponent'
import { createIncident, getIncident } from '../../../../api/incidents'
import { reverseGeocode } from '../../../../helpers/geocode'

import { addNewIncident } from '../../../store/IncidentSlice';
import { resetFlag } from '../../../store/SearchSlice';

import { recordAnalyticEvent, timeAnalyticEvent } from '../../../../api/analytics';

const SEARCH_FLAG_LAYER_ID = "search-flag-layer";


function SearchLayer(props) {
    const [showIncidentSideSheet, toggleIncidentSideSheet] = useState(false);
    const [incidentFocus, setIncidentFocus] = useState({});

    const flagCoordinates = useSelector((state) => state.search.flagCoordinates)

    // useSuspense needs to be set to false in map layers or it will create
    // a race condition, as loading order affects rendering order.
    const { t } = useTranslation('maps', { useSuspense: false })

    const dispatch = useDispatch()

    const handleFlagClick = async () => {
        let strCoordinates = flagCoordinates[1] + ", " + flagCoordinates[0]

        const geocodeRes =  await reverseGeocode(strCoordinates)
        let address = geocodeRes.address || ""
        let city = geocodeRes.locality || ""
        let state = geocodeRes.state || ""
        let zipcode = geocodeRes.postcode || ""
        let country = geocodeRes.country || ''

        setIncidentFocus({
            "incident_summary": "",
            "incident_description": "",
            "incident_priority_name": "high",
            "incident_status_name": "not started",
            "incident_type_name": "",
            "location": {
            "geojson": {
                "type": "Point",
                "coordinates": flagCoordinates
            },
            "thoroughfare": address,
            "locality": city,
            "administrative_area": state,
            "postal_code": zipcode,
            "country": country,
            }
        });

        recordAnalyticEvent("New Pin Drop", {
            "coordinates": flagCoordinates,
            "locality": city,
            "administrative_area": state,
            "postal_code": zipcode,
        })

        toggleIncidentSideSheet(true);
    }

  const handleCreateIncident = (newIncident, callback) => {
    timeAnalyticEvent('Create Incident on Map')
    createIncident(newIncident)
      .then(async (res) => {
        const newIncidentResponse = await getIncident(res.data.incident_id)
        const newIncident = newIncidentResponse && newIncidentResponse.data

        recordAnalyticEvent('Create Incident on Map', {
          new_incident_id: newIncident?.incident_id,
        })

          dispatch(addNewIncident(newIncident))

          setIncidentFocus({})
          toggleIncidentSideSheet(false)
          dispatch(resetFlag())
        }).catch(() => {
        }).finally(() => {
          callback()
        })
    }

    return <React.Fragment>
        <Image id="search-flag" url="/search-flag.png" />
        {!!flagCoordinates && (
            <GeoJSONLayer
                id={SEARCH_FLAG_LAYER_ID}
                data={{
                    "type": "Point",
                    "coordinates": flagCoordinates,
                }}
                symbolLayout={{
                    'icon-image': 'search-flag',
                    'icon-size': 1,
                    'icon-anchor': 'bottom-left',
                    'icon-offset': [-7, 0],
                    'text-field': t('click_to_diagnose'),
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-anchor': 'top',
                    'text-max-width': 20,
                }}
                symbolOnClick={handleFlagClick}
            />
        )}
        <IncidentSheet
            show={showIncidentSideSheet}
            existingIncident={incidentFocus}
            submitHandler={handleCreateIncident}
            closeHandler={() => {
                setIncidentFocus({});
                toggleIncidentSideSheet(false);
            }}
        />
    </React.Fragment>
}

export default SearchLayer
