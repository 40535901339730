import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce-promise'
import { Form, Button, Collapse, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import AsyncSelect from 'react-select/async'
import { searchAssets } from '../../../../api/assets'
import makeAnimated from 'react-select/animated'
import { ReactComponent as RemoveIcon } from '../../../../assets/asset-remove.svg'

import styles from '../../maps/Map.module.scss'
import sideSheetStyles from '../../../../common/incident-side-sheet/incidentsheet.module.css'
import CreateAssetForm from './CreateAssetForm'

const AssetsRelated = ({ assets, removeAssets }) => {
  const [selectedAssets, setSelectedAssets] = useState([])
  const [allAssets, setAllAssets] = useState(assets)

  useEffect(() => {
    removeAssets(selectedAssets)
  }, [removeAssets, selectedAssets])

  const handleRemoveAsset = (asset) => {
    setSelectedAssets((prevSelectedAssets) => {
      const isSelected = prevSelectedAssets.includes(asset.asset_id)
      if (isSelected) {
        return prevSelectedAssets.filter((id) => id !== asset.asset_id)
      } else {
        return [...prevSelectedAssets, asset.asset_id]
      }
    })

    setAllAssets((prevAllAssets) =>
      prevAllAssets.filter((item) => item.asset_id !== asset.asset_id),
    )
  }

  const paragraphs = allAssets.map((asset) => (
    <span key={asset.asset_id} className={styles.relatedAssetContainer}>
      {asset.asset_name}{' '}
      <span
        className={styles.relatedAssetSelector}
        onClick={() => handleRemoveAsset(asset)}
      >
        <RemoveIcon />
      </span>
    </span>
  ))

  return paragraphs
}

const AssetUpdatePanel = ({
  incident,
  relatedAssets,
  createAssets,
  handleRemoveAssets,
}) => {
  const [collapseGI, setCollapseGI] = useState(true)
  const [showCreateAsset, setShowCreateAsset] = useState(false)
  const animatedComponents = makeAnimated()
  const [assetCreated, setAssetCreated] = useState([])
  const [assetSelected, setAssetSelected] = useState([])
  const [possibleAsset, setPossibleAsset] = useState('')
  const [assetRemoved, setAssetRemoved] = useState([])

  const { t } = useTranslation('incidents')

  useEffect(() => {
    handleRemoveAssets(assetRemoved)
  }, [handleRemoveAssets, assetRemoved])

  let showAssetLabels = true

  const onChangeAsset = (e) => {
    setAssetSelected(e)
    createAssets({
      assetsCreated: assetCreated,
      assetsExisting: e,
    })
  }

  const onClearAsset = () => {
    createAssets({
      assetsCreated: assetCreated,
      assetsExisting: [],
    })
  }

  if (
    Object.keys(incident).length > 0 &&
    incident.dependents.filter(
      (dependent) => dependent.record_type === 'assets',
    ).length === 0
  ) {
    showAssetLabels = false
  }

  const onAssetCreated = (assetName, assetId) => {
    setAssetCreated([...assetCreated, { label: assetName, value: assetId }])
    setShowCreateAsset(!showCreateAsset)
    createAssets({
      assetsCreated: [...assetCreated, { label: assetName, value: assetId }],
      assetsExisting: assetSelected,
    })
  }

  const assetNameLookup = async (inputValue) => {
    const searchResults = await searchAssets(
      [
        'node',
        'power supply',
        'pole',
        'network line',
        'hub site',
        'data center',
        'mega pop',
        'wire center',
      ],
      inputValue,
    )
    return searchResults.map((asset) => {
      return {
        label: asset.asset_name,
        value: asset.asset_id,
      }
    })
  }

  const removeAssetsSelected = useCallback(
    (assets) => {
      setAssetRemoved(assets)
    },
    [setAssetRemoved],
  )

  const loadSearchOptions = useCallback(
    debounce(async (inputValue, callback) => {
      const groupedResults = []
      const assetsFound = await assetNameLookup(inputValue)
      if (!!assetsFound) {
        groupedResults.push({
          label: 'Assets',
          options: assetsFound,
        })
      }
      return callback(groupedResults)
    }, 1000),
    [],
  )

  const noOptionsMessage = () => {
    return (
      <div>
        {t('no_results_found')}{' '}
        <span
          className={styles.createAssetButton}
          onClick={() => setShowCreateAsset(true)}
        >
          {t('create_new_asset')}
        </span>
      </div>
    )
  }

  const saveAssetValue = (e) => {
    setPossibleAsset(e)
  }

  return (
    <div className={styles.editAssetContainer}>
      <Row>
        <Button
          onClick={() => setCollapseGI(!collapseGI)}
          aria-controls="collapseGI-text"
          aria-expanded={collapseGI}
          className={sideSheetStyles.collapseSectionHeader}
        >
          <FontAwesomeIcon
            icon={collapseGI ? faAngleDown : faAngleRight}
          ></FontAwesomeIcon>{' '}
          {t('asset_information')}
        </Button>
      </Row>
      <Collapse in={collapseGI}>
        <div className={sideSheetStyles.collapseSectionBody}>
          {showCreateAsset ? (
            <CreateAssetForm
              incident={incident}
              onAssetCreated={onAssetCreated}
              assetTyped={possibleAsset}
            />
          ) : (
            <>
              <Form.Label className={styles.group_label}>
                {t('asset_name')}
              </Form.Label>
              <AsyncSelect
                placeholder={t('associate_an_asset')}
                isMulti
                onInputChange={saveAssetValue}
                components={animatedComponents}
                loadOptions={loadSearchOptions}
                onChange={onChangeAsset}
                onClear={onClearAsset}
                noOptionsMessage={noOptionsMessage}
              />
            </>
          )}
          {(relatedAssets.length > 0 || assetCreated.length > 0) &&
            !showCreateAsset && (
              <div className={styles.assetsContainer}>
                {showAssetLabels && (
                  <AssetsRelated
                    assets={relatedAssets}
                    removeAssets={removeAssetsSelected}
                  />
                )}
                {assetCreated.map((asset) => {
                  return (
                    <span className={styles.createdAssetContainer}>
                      {asset.label}
                    </span>
                  )
                })}
              </div>
            )}
        </div>
      </Collapse>
    </div>
  )
}
export default AssetUpdatePanel
