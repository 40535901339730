import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'

import styles from '../Incidents.module.scss'
import GisualAlert from './../../../../assets/alert.png'

function IncidentDeleteModal(props) {
  const { t } = useTranslation('incidents')

  return (
    <Modal show={props.show} onHide={props.closeHandler}>
      <Modal.Body className={styles.deleteModalBody}>
        <div className={styles.deleteModalBodyContent}>
          <img
            alt="alert-icon"
            className={styles.alertIcon}
            src={GisualAlert}
          />
          <h5 className={styles.deleteModalText}>{t('delete_confirmation')}</h5>
          <p className={styles.deleteModalTextDetail}>{t('delete_info')}</p>
        </div>
        <Button
          variant="light"
          className={styles.cancelDeleteButton}
          onClick={props.closeHandler}
        >
          {t('common:no')}
        </Button>
        <Button
          variant="danger"
          className={styles.confirmDeleteButton}
          onClick={props.confirmHandler}
        >
          {t('common:yes')}
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default IncidentDeleteModal
