export const getLineGeojson = (start, end) => {
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [
        start,
        end,
      ]
    }
  };
};
