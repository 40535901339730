import { createSlice } from '@reduxjs/toolkit'

const SearchSlice = createSlice({
  name: 'search',
  slice: 'search',
  initialState: {
    "flagCoordinates": null,
  },
  reducers: {
    setFlag(state, action) {
      let incoming = action.payload
      state.flagCoordinates = incoming
    },
    resetFlag(state, action) {
      state.flagCoordinates = null
    }
  }
})

export const { setFlag, resetFlag } = SearchSlice.actions

export default SearchSlice.reducer
