import React from 'react'
import { Card, Heading, Pane, Text } from 'evergreen-ui'
import classnames from 'classnames'

import styles from './MapLegend.module.scss'

export const LegendSection = ({ title, children }) => {
  return (
    <Pane>
      {title && (
        <Heading size={400} marginBottom={6}>
          {title}
        </Heading>
      )}
      {children}
    </Pane>
  )
}

export const LegendItem = ({ items, selected = true, onToggle = null }) => {
  return (
    <Pane
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingBottom={2}
    >
      <Pane display="flex" flexDirection="column" gridGap={5}>
        {onToggle && (
          <input
            className={styles.showOnHover}
            type="checkbox"
            checked={selected}
            onChange={() => onToggle(!selected)}
          />
        )}
        {Object.entries(items).map(([label, background]) => (
          <span
            key={label}
            className={classnames({
              [styles.mapLegendDot]: true,
              [styles.hideOnHover]: onToggle != null,
            })}
            style={{ background }}
          />
        ))}
      </Pane>
      <Pane display="flex" flexDirection="column" gridGap={1}>
        {Object.keys(items).map((label) => (
          <Text size={300} key={label}>
            {label}
          </Text>
        ))}
      </Pane>
    </Pane>
  )
}

const MapLegend = ({ offsetBottom = 0, children }) => {
  return (
    <Card
      className={styles.mapLegend}
      elevation={1}
      background="tint1"
      gridGap={12}
      position="absolute"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      paddingX={15}
      paddingY={10}
      bottom={10 + offsetBottom}
      right={10}
    >
      {children}
    </Card>
  )
}

export default MapLegend
