import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useParams } from 'react-router'

import * as jsonpatch from 'fast-json-patch'
import { Layer, Image, MapContext } from 'react-mapbox-gl'

import { updateIncidentWithPatch, getIncident } from '../../../../api/incidents'

import PopupContext from './PopupContext'
import { getValidatingPowerStatus } from '../../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { updateIncident } from '../../../store/IncidentSlice'

const IncidentSheet = React.lazy(() =>
  import('../../../../common/incident-side-sheet/IncidentSheetComponent'),
)
const IncidentPopup = React.lazy(() => import('./IncidentPopup'))

const INCIDENTS_DATA_SOURCE_ID = 'incidents-data-dynamic'

export const INCIDENTS_LAYER = 'incidents-layer'

export const INCIDENT_COLOR_RESOLVED = '#2ECC71'
export const INCIDENT_COLOR_IN_PROGRESS = '#F8E600'
export const INCIDENT_COLOR_POWER_OUT = '#E74C3C'
export const INCIDENT_COLOR_VALIDATING_POWER = '#6e6e6e'

const IncidentClustersLayer = ({ id, color, incidents = [] }) => {
  const [sourceLoaded, setSourceLoaded] = useState(false)

  const map = useContext(MapContext)
  const { handleOpenPopup } = useContext(PopupContext)

  const sourceId = `${id}-source`
  const circlesLayerId = `${id}-circles-layer`
  const countLayerId = `${id}-count-layer`

  useEffect(() => {
    map.addSource(sourceId, {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
      cluster: true,
      clusterRadius: 35,
      clusterMaxZoom: 11,
    })

    setSourceLoaded(true)
  }, [map, sourceId])

  useEffect(() => {
    map.getSource(sourceId).setData({
      type: 'FeatureCollection',
      features: incidents,
    })
  }, [map, incidents, sourceId])

  const handleClick = useCallback(
    (e) => {
      const featureProps = e.features[0].properties
      const zoomLvl = e.target.transform.tileZoom

      if (!featureProps.cluster) {
        if (zoomLvl < 8) {
          map.flyTo({
            center: e.lngLat,
            zoom: zoomLvl + 2,
            speed: 2,
          })
        }

        handleOpenPopup('incident', {
          ...Object.entries(featureProps).reduce(
            (incident_properties, [key, value]) => ({
              ...incident_properties,
              [key]: value === 'null' ? null : value,
            }),
            {},
          ),
          blockers: JSON.parse(featureProps?.blockers),
          dependents: JSON.parse(featureProps?.dependents),
          location: {
            ...JSON.parse(featureProps.location),
            geojson: e.features[0].geometry,
          },
          metadata: JSON.parse(featureProps?.metadata),
        })
      } else if (featureProps.cluster) {
        map.flyTo({
          center: e.lngLat,
          zoom: zoomLvl + 2,
          speed: 2,
        })
      }
    },
    [map, handleOpenPopup],
  )

  return (
    sourceLoaded && (
      <>
        <Layer
          id={circlesLayerId}
          sourceId={sourceId}
          type="circle"
          paint={{
            'circle-color': color,
            'circle-radius': 14,
          }}
          onClick={handleClick}
          maxZoom={12}
        />
        <Layer
          id={countLayerId}
          sourceId={sourceId}
          type="symbol"
          layout={{
            'text-field': ['get', 'point_count'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          }}
          paint={{
            'text-color': '#FFFFFF',
          }}
          maxZoom={12}
        />
      </>
    )
  )
}

const IncidentMarkersLayer = ({
  powerOnIncidents,
  powerOffIncidents,
  validatingPowerIncidents,
}) => {
  const map = useContext(MapContext)

  const [sourceLoaded, setSourceLoaded] = useState(false)

  const powerOnIncidentIds = useMemo(
    () => powerOnIncidents.map((incident) => incident.properties.incident_id),
    [powerOnIncidents],
  )

  const validatingPowerIncidentsIds = useMemo(
    () =>
      validatingPowerIncidents.map(
        (incident) => incident.properties.incident_id,
      ),
    [validatingPowerIncidents],
  )

  useEffect(() => {
    map.addSource(INCIDENTS_DATA_SOURCE_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    })

    setSourceLoaded(true)
  }, [map])

  useEffect(() => {
    map.getSource(INCIDENTS_DATA_SOURCE_ID).setData({
      type: 'FeatureCollection',
      features: [
        ...powerOnIncidents,
        ...powerOffIncidents,
        ...validatingPowerIncidents,
      ],
    })
  }, [map, powerOnIncidents, powerOffIncidents, validatingPowerIncidents])

  return (
    sourceLoaded && (
      <>
        <Layer
          id={INCIDENTS_LAYER}
          sourceId={INCIDENTS_DATA_SOURCE_ID}
          type="symbol"
          filter={['!', ['has', 'point_count']]}
          layout={{
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            'icon-image': [
              'case',
              ['in', ['get', 'incident_id'], ['literal', powerOnIncidentIds]],
              'incident-resolved',
              [
                'in',
                ['get', 'incident_id'],
                ['literal', validatingPowerIncidentsIds],
              ],
              'incident-validating-power',
              [
                'match',
                ['get', 'incident_status_name'],
                'not started',
                'incident-not-started',
                'in progress',
                'incident-in-progress',
                'resolved',
                'incident-resolved',
                'incident-not-started',
              ],
            ],
            'icon-size': 1,
          }}
          paint={{
            'icon-translate': [0, 20],
          }}
          minZoom={12}
        />
      </>
    )
  )
}

const IncidentLayer = () => {
  const incidents = useSelector((state) => state.incidents.incidents)
  const incidentsFetchTime = useSelector(
    (state) => state.incidents.lastFetchTime,
  )

  const dispatch = useDispatch()

  const { id: urlParamsId, type: urlParamsType } = useParams()

  const [selectedIncident, setSelectedIncident] = useState(null)
  const [urlIncident, setUrlIncident] = useState(null)

  const {
    currentPopupType,
    popupData,
    flipcard,
    togglePopupFlip,
    handleClosePopup,
  } = useContext(PopupContext)

  const { powerOnIncidents, powerOffIncidents, validatingPowerIncidents } =
    useMemo(() => {
      const powerOnIncidents = []
      const powerOffIncidents = []
      const validatingPowerIncidents = []

      const features = incidents.map((incident) => ({
        type: 'Feature',
        geometry: incident.location.geojson,
        properties: incident,
      }))

      if (urlIncident) {
        const incidentIds = features.map((i) => i.properties.incident_id)

        if (!incidentIds.includes(urlIncident.properties.incident_id)) {
          features.push(urlIncident)
        }
      }

      features.forEach((incident) => {
        const powerStatus = getValidatingPowerStatus(
          incident.properties,
          incidentsFetchTime,
        )

        if (
          powerStatus === 'power on' ||
          incident.properties.incident_status_name === 'resolved'
        ) {
          powerOnIncidents.push(incident)
        } else if (powerStatus === 'validating power') {
          validatingPowerIncidents.push(incident)
        } else {
          powerOffIncidents.push(incident)
        }
      })

      return {
        powerOnIncidents,
        powerOffIncidents,
        validatingPowerIncidents,
      }
    }, [incidents, incidentsFetchTime, urlIncident])

  const fetchUrlIncident = useCallback(async () => {
    if (urlParamsType === 'incident') {
      try {
        const { data: incident } = await getIncident(
          urlParamsId,
          {
            headers: {
              Accept: 'application/geo+json',
            }
          },
        )

        setUrlIncident(incident)
      } catch {
        // If we fail to fetch the incident, stop drawing it if we
        // fetched it previously, since it means it was deleted
        setUrlIncident(null)
      }
    } else {
      setUrlIncident(null)
    }
  }, [urlParamsId, urlParamsType])

  const performEditIncident = async (newValues, callback) => {
    let editingIncident = JSON.parse(JSON.stringify(selectedIncident))
    let patch = jsonpatch.compare(editingIncident, newValues)

    try {
      await updateIncidentWithPatch(
        selectedIncident.incident_id,
        patch,
        newValues,
      )
      dispatch(updateIncident(newValues))
      handleClosePopup()
      setSelectedIncident(null)
    } catch (error) {
    } finally {
      callback()
    }

    await fetchUrlIncident()
  }

  useEffect(() => {
    // If there is an incident ID in the URL params, always fetch and draw it.
    fetchUrlIncident()

    // Set an interval to update URL incident every 60 seconds
    const interval = setInterval(fetchUrlIncident, 60000)

    // Clear the interval once the component is unmounted.
    return () => clearInterval(interval)
  }, [fetchUrlIncident])

  return (
    <>
      <Image id="incident-not-started" url="/incident-pins/not-started.png" />
      <Image id="incident-in-progress" url="/incident-pins/in-progress.png" />
      <Image id="incident-resolved" url="/incident-pins/resolved.png" />
      <Image
        id="incident-validating-power"
        url="/incident-pins/validating.png"
      />

      <IncidentClustersLayer
        id="incident-clusters-resolved"
        color={INCIDENT_COLOR_RESOLVED}
        incidents={powerOnIncidents}
      />
      <IncidentClustersLayer
        id="incident-clusters-power-off"
        color={INCIDENT_COLOR_POWER_OUT}
        incidents={powerOffIncidents}
      />
      <IncidentClustersLayer
        id="incident-clusters-validating-power"
        color={INCIDENT_COLOR_VALIDATING_POWER}
        incidents={validatingPowerIncidents}
      />
      <IncidentMarkersLayer
        powerOnIncidents={powerOnIncidents}
        powerOffIncidents={powerOffIncidents}
        validatingPowerIncidents={validatingPowerIncidents}
      />

      <Suspense fallback={null}>
        {currentPopupType === 'incident' && (
          <IncidentPopup
            toggleIncidentEditor={(incident) => setSelectedIncident(incident)}
            {...{ popupData, flipcard, togglePopupFlip, handleClosePopup }}
          />
        )}
      </Suspense>

      <Suspense fallback={null}>
        <IncidentSheet
          show={!!selectedIncident}
          existingIncident={selectedIncident}
          submitHandler={performEditIncident}
          deleteHandler={() => {
            handleClosePopup()
            fetchUrlIncident()
          }}
          closeHandler={() => setSelectedIncident(null)}
        />
      </Suspense>
    </>
  )
}

export default IncidentLayer
