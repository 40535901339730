import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form, Col, Button, Collapse, Row } from 'react-bootstrap'
import debounce from 'debounce-promise'
import AsyncCreatableSelect from 'react-select/async-creatable'
import AssetUpdatePanel from '../../app/components/incidents/partials/AssetUpdatePanel'
import TicketInformationPanel from '../../app/components/incidents/partials/TicketInformationPanel'
import { SideSheet } from 'evergreen-ui'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'
import { toaster } from 'evergreen-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { formatCityState, formatIncidentType } from '../../helpers'
import {
  coordinateParse,
  reverseGeocode,
  forwardGeocode,
} from '../../helpers/geocode'

import { removeIncident } from '../../app/store/IncidentSlice'

import styles from './incidentsheet.module.css'

import {
  getAllIncidentTypes,
  createIncidentType,
  deleteIncident,
} from '../../api/incidents'


import IncidentDeleteModal from '../../app/components/incidents/partials/DeleteModal'
import { searchAssets } from '../../api/assets'
import addressBarStyles from '../address-bar/addressBarStyles'

const NEW_INCIDENT_VALID_TYPES = ['unknown', 'service outage']

const confirmDelete = (incident, callback, t) => {
  if (incident) {
    deleteIncident(incident.incident_id)
      .then((res) => {
        callback()
      })
      .catch((err) => {
        toaster.danger(t('incident_deletion_error'))
      })
  }
}

function IncidentLocationField(props) {
  const {
    formState,
    setFormState,
    setLocation,
    setThoroughfare,
    setLocality,
    setState,
    setPostalCode,
    setCountry,
  } = props

  const addressBarRef = useRef()
  const [inputValue, setInputValue] = useState()
  const [lastInputValue, setLastInputValue] = useState('')
  const [selectedValue, setSelectedValue] = useState()

  const { t } = useTranslation('incidents')

  const noOptionsMessage = () => {
    return t('no_options_available')
  }

  const loadingMessage = () => {
    return t('common:loading')
  }

  const coordCreateLabel = (inputValue) => {
    return t('add_coordinates') + inputValue
  }

  const checkForCoordinates = (inputValue) => {
    const coordinateTest = coordinateParse(inputValue)

    if (coordinateTest && coordinateTest.length === 5) {
      return true
    } else {
      return false
    }
  }

  const assetNameLookup = async (inputValue) => {
    const moveAssetLocation = 0.00005
    const searchResults = await searchAssets(
      ['node', 'power supply'],
      inputValue,
    )

    return searchResults.map((asset) => {
      const assetLocation = asset.location.geojson

      // Shift the original asset location by ~5.5593 meters to prevent
      // overlapping UI bug on incidents created by asset location.
      const newLat = assetLocation.coordinates[1] + moveAssetLocation

      return {
        label: asset.asset_name,
        value: asset.asset_id,
        data: {
          optionType: 'asset',
          latitude: newLat,
          longitude: assetLocation.coordinates[0],
        },
      }
    })
  }

  const loadSearchOptions = useCallback(
    debounce(async (inputValue, callback) => {
      if (checkForCoordinates(inputValue)) return callback([])

      const groupedResults = []

      const assetsFound = await assetNameLookup(inputValue)

      if (!!assetsFound && assetsFound.length > 0) {
        groupedResults.push({
          label: t('common:assets'),
          options: assetsFound,
        })
      }

      if (inputValue.length >= 5) {
        const addressesFound = await forwardGeocode(inputValue)

        if (!!addressesFound && addressesFound.length > 0) {
          groupedResults.push({
            label: t('common:addresses'),
            options: addressesFound,
          })
        }
      }

      setFormState({
        display: { label: '', value: '' },
        options: groupedResults,
      })
      return callback(groupedResults)
    }, 1000),
    [t],
  )

  const createCoordinatePairOption = (inputValue) => {
    reverseGeocode(inputValue).then((resultArray) => {
      if (!!resultArray) {
        let menulabel = `${resultArray.locality}, ${resultArray.state} ${resultArray.postcode}`

        if (!!resultArray.address && resultArray.address !== '') {
          menulabel = `${resultArray.address}, ${menulabel}`
          setThoroughfare(resultArray.address)
        }

        setLocation({ lat: resultArray.lat, lng: resultArray.lng })
        setLocality(resultArray.locality)
        setState(resultArray.state)
        setPostalCode(resultArray.postcode)
        setCountry(resultArray.country)
        setFormState({ display: { label: menulabel, value: inputValue } })
      }
    })
  }

  const selectAddressOption = (selectedOption, action) => {
    if (action && action.action === 'select-option') {
      /*
        If the selected option is an asset, perform a reverse geocode on the coordinates of the asset
        to get the asset's City, State, and Zipcode to be included in the new incident request.
      */
      if (
        !!selectedOption.data.optionType &&
        selectedOption.data.optionType === 'asset' &&
        !!selectedOption.data.latitude &&
        !!selectedOption.data.longitude
      ) {
        reverseGeocode(
          `${selectedOption.data.latitude}, ${selectedOption.data.longitude}`,
        ).then((resultArray) => {
          if (!!resultArray && resultArray.lat && resultArray.lng) {
            if (!!resultArray.address && resultArray.address !== '') {
              setThoroughfare(resultArray.address)
            }

            setLocation({ lat: resultArray.lat, lng: resultArray.lng })
            setLocality(resultArray.locality)
            setState(resultArray.state)
            setPostalCode(resultArray.postcode)
            setCountry(resultArray.country)
            setFormState({
              display: {
                label: selectedOption.label,
                value: selectedOption.value,
              },
            })
          }
        })
      } else {
        setFormState({
          display: { label: selectedOption.label, value: selectedOption.value },
        })
        setLocation({
          lat: selectedOption.data.latitude,
          lng: selectedOption.data.longitude,
        })
        setThoroughfare(selectedOption.data.address)
        setLocality(selectedOption.data.city)
        setState(selectedOption.data.state)
        setCountry(selectedOption.data.country)
        setPostalCode(selectedOption.data.postcode)
      }

      setSelectedValue(selectedOption)
      setLastInputValue(selectedOption.label)
    }
  }

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      setInputValue(newValue)
      setSelectedValue(null)
      setLocation({})
      setLastInputValue('')
    } else if (action === 'set-value') {
      setInputValue(newValue)
    } else if (action === 'menu-close') {
      if (inputValue) {
        setLastInputValue(inputValue)
        setInputValue(newValue)
        setSelectedValue({ label: inputValue })
      }
      addressBarRef.current.blur()
    }
  }

  const handleOnFocus = () => {
    if (!inputValue) {
      setInputValue(lastInputValue)
    }
  }

  return (
    <AsyncCreatableSelect
      ref={addressBarRef}
      styles={addressBarStyles}
      classNamePrefix="address-bar"
      name="location"
      placeholder={t('enter_latitude_longtitude')}
      maxMenuHeight={500}
      formatCreateLabel={coordCreateLabel}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={loadingMessage}
      cacheOptions
      isValidNewOption={checkForCoordinates}
      loadOptions={loadSearchOptions}
      onCreateOption={createCoordinatePairOption}
      defaultValue={formState.display}
      onChange={selectAddressOption}
      inputValue={inputValue}
      value={selectedValue}
      onFocus={handleOnFocus}
      onInputChange={handleInputChange}
      blurInputOnSelect={true}
    />
  )
}

const mapState = (state) => ({ incidents: state.incidents.json })
const mapDispatch = { removeIncident }

function IncidentSheet(props) {
  const {
    show,
    existingIncident,
    intelRequest,
    submitHandler,
    deleteHandler,
    closeHandler,
    removeIncident,
    relatedAssets,
    onChangeAssets,
    handleRemoveAssets,
  } = props

  const { t } = useTranslation('incidents')
  const priorityOptions = [
    { label: t('low'), value: 'low' },
    { label: t('medium'), value: 'medium' },
    { label: t('high'), value: 'high' },
  ]

  const statusOptions = [
    { label: t('not_started'), value: 'not started' },
    { label: t('in_progress'), value: 'in progress' },
    { label: t('resolved'), value: 'resolved' },
  ]

  const [type, setType] = useState({ id: '', value: '', label: '' }),
    [defaultType, setDefaultType] = useState(),
    [priority, setPriority] = useState(priorityOptions[2].value),
    [status, setStatus] = useState(statusOptions[0].value),
    [location, setLocation] = useState({}),
    [thoroughfare, setThoroughfare] = useState(''),
    [locality, setLocality] = useState(),
    [state, setState] = useState(),
    [postalCode, setPostalCode] = useState(),
    [country, setCountry] = useState(),
    [name, setName] = useState(),
    [task, setTask] = useState('action_create'),
    [incidentTypeOptions, setIncidentTypeOptions] = useState(),
    [showDeleteModal, toggleDeleteModal] = useState(false),
    [incidentEtr, setIncidentEtr] = useState(null),
    [locationFieldState, setLocationFieldState] = useState({
      display: { label: '', value: '' },
      options: [],
    }),
    [loading, setLoading] = useState(false),
    [formReady, setFormReady] = useState(false),
    [savingIntelRequest, setSavingIntelRequest] = useState({})

  const reloadIncidentTypesList = () => {
    getAllIncidentTypes().then(function (res) {
      let values = res.data
        .filter(({ incident_type_name }) => {
          // Only display Unknown and Service Outage
          return NEW_INCIDENT_VALID_TYPES.includes(incident_type_name)
        })
        .map((singleType) => {
          const incidentTypeDef = {
            label: formatIncidentType(t, singleType.incident_type_name),
            value: singleType.incident_type_name,
            id: singleType.incident_type_id,
          }

          if (
            singleType.incident_type_name === 'service outage' &&
            type.label === '' &&
            type.value === ''
          ) {
            setType(incidentTypeDef)
            setDefaultType(incidentTypeDef)
          }

          return incidentTypeDef
        })
      setIncidentTypeOptions(values)
    })
  }

  useEffect(() => {
    setFormReady(
      !!location.lat &&
        !!location.lng &&
        !!type.value &&
        !!priority &&
        !!status,
    )
  }, [location, type, priority, status])

  useEffect(() => {
    const editing = existingIncident

    if (editing && editing.hasOwnProperty('location')) {
      if (typeof editing.incident_id === 'undefined') {
        setTask('action_add')
      } else {
        setTask('action_edit')
      }

      setName(editing.incident_name)
      setPriority(editing.incident_priority_name)
      setStatus(editing.incident_status_name)
      setIncidentEtr(
        !!editing.incident_etr
          ? DateTime.fromISO(editing.incident_etr).valueOf()
          : null,
      )
      setThoroughfare(editing.location.thoroughfare)
      setLocality(editing.location.locality)
      setState(editing.location.administrative_area)
      setPostalCode(editing.location.postal_code)
      setCountry(editing.location.country)
      setLocation({
        lat: editing.location.geojson.coordinates[1],
        lng: editing.location.geojson.coordinates[0],
      })
      if (!!editing.incident_type_name && editing.incident_type_name !== '') {
        setType({
          id: null,
          value: editing.incident_type_name,
          label: formatIncidentType(t, editing.incident_type_name),
        })
      }

      let locationFieldString = `${formatCityState(
        editing.location.locality,
        editing.location.administrative_area,
      )} ${editing.location.postal_code}`
      if (!!editing.location.thoroughfare) {
        locationFieldString = `${editing.location.thoroughfare}, ${locationFieldString}`
      }
      setLocationFieldState({
        display: { label: locationFieldString, value: '1' },
      })

    }

    reloadIncidentTypesList()
  }, [existingIncident])

  const filterTypeOptions = (inputValue) => {
    return incidentTypeOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    )
  }

  const handleIntelRequestChange = (savingIntelRequest) => {
    setSavingIntelRequest(savingIntelRequest)
  };

  const loadTypeOptions = (inputValue, callback) => {
    return callback(filterTypeOptions(inputValue))
  }

  const handleITypeCreate = (inputVal) => {
    let validType = RegExp('^[a-zA-Z0-9. ]*$')
    if (validType.test(inputVal)) {
      const value = inputVal.toLowerCase()

      createIncidentType(value).then((res) => {
        reloadIncidentTypesList()
        setType({
          id: res.data.incident_type_id,
          value: value,
          label: inputVal,
        })
        return inputVal
      })
    }
  }

  const formatInputDatetime = (datetime) => {
    if (!datetime) {
      return null
    }

    let formatted = String(datetime).split(' GMT').join(' ').split(' (')[0]
    return DateTime.fromFormat(formatted, 'EEE MMM dd yyyy HH:mm:ss ZZZ')
  }

  const pressSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    if (
      props.hasOwnProperty('existingIncident') &&
      existingIncident.hasOwnProperty('location')
    ) {
      let savingIncident = JSON.parse(JSON.stringify(existingIncident))
      savingIncident.incident_type_name = type.value
      savingIncident.incident_priority_name = priority
      savingIncident.incident_status_name = status
      savingIncident.incident_etr = formatInputDatetime(incidentEtr)
      savingIncident.location.geojson.coordinates[1] = parseFloat(location.lat)
      savingIncident.location.geojson.coordinates[0] = parseFloat(location.lng)
      savingIncident.location.thoroughfare = thoroughfare
      savingIncident.location.locality = locality
      savingIncident.location.administrative_area = state
      savingIncident.location.postal_code = postalCode
      savingIncident.location.country = country
      // checking for edit incident with intel_request(ticketId)
      if (Object.keys(savingIntelRequest).length > 0) {
        submitHandler(savingIncident, savingIntelRequest, () => setLoading(false))
      } else {
        submitHandler(savingIncident, () => setLoading(false))
      }
    } else {
      submitHandler(
        {
          priority,
          status,
          type: type.value,
          incident_etr: formatInputDatetime(incidentEtr),
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.lng),
          thoroughfare,
          locality,
          state,
          postalCode,
          country,
        },
        () => setLoading(false),
      )
    }

    // Reset state variables to blank upon submit
    setType(defaultType)
    setPriority(priorityOptions[2].value)
    setStatus(statusOptions[0].value)
    setIncidentEtr(null)
    setLocation({})
    setLocality('')
    setThoroughfare('')
    setState('')
    setPostalCode('')
    setCountry('')
    setName('')
    setLocationFieldState({ display: { label: '', value: '' }, options: [] })
  }

  const closeHandlerSub = () => {
    setType(defaultType)
    setPriority(priorityOptions[2].value)
    setStatus(statusOptions[0].value)
    setLocation({})
    setThoroughfare('')
    setLocality('')
    setState('')
    setPostalCode('')
    setCountry('')
    setName('')
    setTask('action_create')
    setIncidentEtr(null)
    setLocationFieldState({ display: { label: '', value: '' }, options: [] })

    closeHandler()
  }

  const [collapseGI, setCollapseGI] = useState(true)

  return (
    <SideSheet isShown={show} onCloseComplete={closeHandlerSub}>
      <div className={styles.is_container}>
        <h3>{t('incident_action', { task: t(task) })}</h3>
        {name ? (
          <div className={styles.is_name}>
            <p>
              {t('incident_name')} {name}
            </p>
          </div>
        ) : (
          ''
        )}
        <Form className={styles.is_form}>
          <div className={styles.generalInformation}>
            <Row>
              <Button
                onClick={() => setCollapseGI(!collapseGI)}
                aria-controls="collapseGI-text"
                aria-expanded={collapseGI}
                className={styles.collapseSectionHeader}
              >
                <FontAwesomeIcon
                  icon={collapseGI ? faAngleDown : faAngleRight}
                ></FontAwesomeIcon>{' '}
                {t('general_information')}
              </Button>
            </Row>
            <Collapse in={collapseGI}>
              <div className={styles.collapseSectionBody}>
                <Row>
                  <Col className={styles.is_col}>
                    <Form.Label>{t('location')}</Form.Label>
                    <IncidentLocationField
                      formState={locationFieldState}
                      setFormState={setLocationFieldState}
                      setLocation={setLocation}
                      setThoroughfare={setThoroughfare}
                      setLocality={setLocality}
                      setState={setState}
                      setPostalCode={setPostalCode}
                      setCountry={setCountry}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.is_col}>
                    <Form.Label>{t('incident_type')}</Form.Label>
                    <AsyncCreatableSelect
                      value={type}
                      onCreateOption={handleITypeCreate}
                      defaultOptions={incidentTypeOptions}
                      loadOptions={loadTypeOptions}
                      onChange={(val) => {
                        setType(val)
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          {relatedAssets && (
            <AssetUpdatePanel
              incident={existingIncident}
              relatedAssets={relatedAssets}
              createAssets={onChangeAssets}
              handleRemoveAssets={handleRemoveAssets}
            />
          )}
          {intelRequest && (
            <TicketInformationPanel
              intelRequest={intelRequest}
              updateIntelRequest={handleIntelRequestChange}
            />
          )}
          <Row>
            <Col className={styles.is_col}>
              <Button variant="light" onClick={closeHandlerSub}>
                {t('common:close')}
              </Button>
            </Col>
            {existingIncident?.incident_id && (
              <Col className={styles.is_col}>
                <Button
                  onClick={() => toggleDeleteModal(true)}
                  variant="danger"
                >
                  {t('common:delete')}
                </Button>
              </Col>
            )}
            <Col className={styles.is_col}>
              <Button
                onClick={pressSubmit}
                variant="success"
                disabled={!formReady || loading}
                className={styles.submitButton}
              >
                {loading
                  ? t('common:saving_changes')
                  : t('common:save_changes')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <IncidentDeleteModal
        show={showDeleteModal}
        confirmHandler={() =>
          confirmDelete(
            existingIncident,
            function () {
              removeIncident(existingIncident.incident_id)
              if (!!deleteHandler) deleteHandler()
              toggleDeleteModal(false)
              closeHandlerSub()
            },
            t,
          )
        }
        closeHandler={() => toggleDeleteModal(false)}
      />
    </SideSheet>
  )
}

export default connect(mapState, mapDispatch)(IncidentSheet)
