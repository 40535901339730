import {incidentMapboxReverseGeocode, incidentMapboxGeocode} from '../api/location'
import { recordAnalyticEvent } from '../api/analytics'
import Coordinates from 'coordinate-parser'

export function coordinateParse(inputValue) {
  const testInput = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/
  try {
    const position = new Coordinates(inputValue)
    inputValue = position.getLatitude() + ", " + position.getLongitude()
  } catch (err) {
    // if coordinate parsing fails, keep original input
  }
  return inputValue.match(testInput)
}

export function reverseGeocode(inputValue) {
  const coordinateParsed = coordinateParse(inputValue)

  if (coordinateParsed && coordinateParsed.length === 5) {
    const lat = coordinateParsed[1]
    const lng = coordinateParsed[3]
    return incidentMapboxReverseGeocode(lng, lat)
    .then(function(geocode) {
      if (geocode.length === 0) {
        recordAnalyticEvent("Reverse Geocode Failure", {
          "inputValue": inputValue,
          "result": geocode
        })
        return []
      }
      let address = geocode.address || ""
      let locality = geocode.place || ""
      let state = geocode.region || ""
      let postcode = geocode.postcode || ""
      let country = geocode.country || ""
      return {address, locality, state, postcode, country, lng, lat}
    })
    .catch((err) => {
      recordAnalyticEvent("Reverse Geocode Failure", {
        "inputValue": inputValue,
        "error": err
      })
    })
  }
}

export function forwardGeocode(inputValue) {
  return incidentMapboxGeocode(inputValue)
  .then(function(geocode) {
    let newFeaturesList = [];

    if (geocode.length === 0) {
      recordAnalyticEvent("Forward Geocode Failure", {
        "inputValue": inputValue,
        "result": geocode
      })
      return []
    }

    for (const newFeature of geocode) {
      let featureAddress = ""
      let featureCity = ""
      let featureState = ""
      let featurePostcode = ""

      if (!newFeature.hasOwnProperty('context')) continue

      newFeature.context.forEach(element => {
        if (/place/.test(element.id)) {
          featureCity = element.text
        } else if (/region/.test(element.id)) {
          featureState = element.text
        } else if (/postcode/.test(element.id)) {
          featurePostcode = element.text
        }
      })

      if (newFeature.hasOwnProperty('address') && newFeature.hasOwnProperty('text')) {
        featureAddress = newFeature.address + " " + newFeature.text
      }

      newFeaturesList.push({
        label: newFeature.place_name,
        value: newFeature.id,
        data: {
          "address": featureAddress,
          "city": featureCity,
          "state": featureState,
          "postcode": featurePostcode,
          "latitude": newFeature.geometry.coordinates[1],
          "longitude": newFeature.geometry.coordinates[0]
        }
      })
    }

    return newFeaturesList
  })
  .catch((err) => {
    recordAnalyticEvent("Forward Geocode Failure", {
      "inputValue": inputValue,
      "error": err
    })
  })
}

// send whole output so we can use mapbox api's BBOX response object for our bounding box reset.
export function addressLookup(inputValue) {
  return incidentMapboxGeocode(inputValue)
  .then(function(geocode) {
    let newFeaturesList = [];
    if (geocode.length === 0) return []

    for (const newFeature of geocode) {
      newFeaturesList.push({
        label: newFeature.place_name,
        value: newFeature.id,
        data: {
          "bbox": newFeature.hasOwnProperty["bbox"] && newFeature.bbox,
          "center": newFeature.center,
        }
      })
    }

    return newFeaturesList;
  })
  .catch((err) => {
    recordAnalyticEvent("Address Lookup Failure", {
      "inputValue": inputValue,
      "error": err
    })
  })
}
