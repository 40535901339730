import mixpanel from 'mixpanel-browser'
import axios from 'axios'

let currentUserTrackingInfo = {};
let isMixpanelInitialized = !!window.MIXPANEL_PROJECT_TOKEN

async function getUserIP() {
  if (currentUserTrackingInfo && currentUserTrackingInfo.hasOwnProperty("ip")) {
    return currentUserTrackingInfo.ip
  } else {
    let ipRes = await axios.get("https://api.ipify.org?format=json")
    currentUserTrackingInfo = { ...currentUserTrackingInfo, ...ipRes.data }
    return currentUserTrackingInfo.ip
  }
}

/**
 * Start a timer to track an event's length.
 * @param String eventName
 */
export function timeAnalyticEvent(eventName) {
  if (!isMixpanelInitialized) return;

  mixpanel.time_event(eventName)
}

/**
 * Record or time an event performed by a user, to be sent to Mixpanel for analysis.
 * @param String eventName
 * @param Object eventData
 */
export async function recordAnalyticEvent(eventName, eventData = {}) {
  if (!isMixpanelInitialized) return;

  let userip = await getUserIP()
  eventData = { ...eventData, "current_ip": userip }

  mixpanel.track(eventName, eventData)
}

/**
 * Run when a user logs in.
 * @param String distinctId
 * @param Object userData
 */
export async function identifyUser(distinctId, userData = {}) {
  if (!isMixpanelInitialized) return;

  let userip = await getUserIP()
  userData = { ...userData, "current_ip": userip }

  mixpanel.identify(distinctId)
  mixpanel.people.set(userData)

  if (userData.hasOwnProperty("organization_id")) {
    mixpanel.set_group("organization", userData.organization_id)
  }

  if (userData.hasOwnProperty("current_ip")) {
    mixpanel.set_group("associated_ip_addresses", userData.current_ip)
  }

  if (userip && userData.hasOwnProperty("current_ip")) {
    mixpanel.track("Login With IP", {
      "current_ip": userData.current_ip
    })
  }
}
