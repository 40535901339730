import React, { Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
import { ScaleControl, ZoomControl } from "react-mapbox-gl";

import GlobalStyles from './../../global.module.css'
import styles from './Map.module.scss'

import { DEFAULT_MAP_BOUNDS, computeBoundsForPolygon, computeBoundsForMultiPolygon } from '../../../helpers/extents.js';

import { PopupProvider } from './partials/PopupContext'

import IncidentsLayer from './partials/IncidentsLayer.jsx';
import AssetsLayer from './partials/AssetsLayer.jsx';
import OutagesLayer from './partials/OutagesLayer.jsx';
import WeatherDataLayers from './partials/WeatherDataLayers.jsx';
import SearchLayer from './partials/SearchLayer.jsx';

import { LayerFilterProvider, LayerFilterController } from './partials/Legend.jsx';

import MapClickControl from './partials/MapClickControl.jsx';
import MapMoveControl from './partials/MapMoveControl.jsx';
import { MapExternalsController } from './partials/MapExternalsControl'

import { getAllAssetsByType } from '../../../api/assets'
import { setTerritories, setBounds, setNetworkLines } from '../../store/AssetSlice'
import RelationshipLinesLayer from './partials/RelationshipLinesLayer';
import PowerSuppliesLayer from './partials/PowerSuppliesLayer';
import useIncidentsLoader from '../../../helpers/useIncidentsLoader';
import MapboxMap from '../../../common/MapboxMap';
import { useParams } from 'react-router-dom';

const Sidebar = React.lazy(() => import('./../../../common/sidebar/SidebarComponent'))
const MiniListView = React.lazy(() => import('./partials/MiniListView'))
const UtilityRanking = React.lazy(() => import('./partials/UtilityRanking'))
const AddIncidentControl = React.lazy(() => import('./partials/AddIncidentControl.jsx'))
const Legend = React.lazy(() => import('./partials/Legend'))
const MapSearchBarControl = React.lazy(() => import('./partials/MapSearchBarControl'))

const mapState = state => ({
  serviceTerritories: state.assets.serviceTerritories,
  territoryBounds: state.assets.territoryBounds,
  networkLines: state.assets.networkLines
})
const mapDispatch = {
  setTerritories,
  setBounds,
  setNetworkLines,
}

function MapViewComponent(props) {
  const {
    networkLines,
    setNetworkLines,
  } = props

  const urlParams = useParams()

  // Start loading incidents early to reduce map loading times
  useIncidentsLoader()

  // Eventually we want to separate this logic out of the MapComponent to it's own asset-related component
  useEffect(() => {
    if (props.serviceTerritories.features.length === 0) {
      getAllAssetsByType('service territory')
      .then((resultTerritories) => {
        props.setTerritories(resultTerritories)

        const padding = 0.05
        let minLongitude = 180;
        let minLatitude = 90;
        let maxLongitude = -180;
        let maxLatitude = -90;

        resultTerritories.features.forEach(function(feature) {
          let miny = 0;
          let minx = 0;
          let maxy = 0;
          let maxx = 0;

          if (feature.geometry.type === 'Polygon') {
            [[miny, minx], [maxy, maxx]] = computeBoundsForPolygon(feature.geometry.coordinates);
          } else if (feature.geometry.type === 'MultiPolygon') {
            [[miny, minx], [maxy, maxx]] = computeBoundsForMultiPolygon(feature.geometry.coordinates);
          }

          if (miny !== 0 && maxy !== 0) {
            minLongitude = Math.min(minLongitude, miny);
            minLatitude = Math.min(minLatitude, minx);
            maxLongitude = Math.max(maxLongitude, maxy);
            maxLatitude = Math.max(maxLatitude, maxx);
          }
        });

        if (minLongitude === 180 && minLatitude === 90) {
          [[minLongitude, minLatitude], [maxLongitude, maxLatitude]] = DEFAULT_MAP_BOUNDS
        }

        props.setBounds([[minLongitude - padding, minLatitude - padding], [maxLongitude + padding, maxLatitude + padding]])
      })
    }
  }, [])

  // Load network lines if needed
  useEffect(() => {
    if (!networkLines.loaded) {
      getAllAssetsByType('network line')
      .then(setNetworkLines)
    }
  }, [networkLines.loaded, setNetworkLines])

  const getInitialViewportProperties = () => {
    // If and only if all three coordinates are defined, we should attempt to zoom to that location
    const {z, x, y} = urlParams;

    if (!!z && !!x && !!y) {
      return {
        center: [x, y],
        zoom: [z]
      }
    }

    return {
      fitBounds: props.territoryBounds.length > 0 ? props.territoryBounds : DEFAULT_MAP_BOUNDS,
      fitBoundsOptions: {
        duration: 0
      },
    };
  }

  return (
    <div className={GlobalStyles.PageContainer}>
      {/* Show sidebar if and only if device width is desktop or larger */}
      <PopupProvider>
        <Suspense fallback={null}>
          <Sidebar activeListItem="map" displayMapItems>
            <MiniListView />
            <UtilityRanking />
          </Sidebar>
        </Suspense>
        <div className={styles.mapAndSearchContainer}>
          <LayerFilterProvider>
            <div id="map" className={styles.innerMapContainer}>
              <Suspense fallback={null}><MapSearchBarControl /></Suspense>
              {props.territoryBounds.length === 0 && (
                <div className={styles.mapLoader} />
              )}
              <MapboxMap
                mapStyle="mapbox://styles/mapbox/light-v10"
                {...getInitialViewportProperties()}
                containerStyle={{
                  height: "100%",
                  width: "100%"
                }}
              >
                <Suspense fallback={null}>
                  <MapExternalsController urlParams={urlParams} />
                  <LayerFilterController />
                  <ScaleControl />
                  <ZoomControl position="bottom-left" />
                  <AddIncidentControl />
                  <AssetsLayer />
                  <PowerSuppliesLayer />
                  <OutagesLayer />
                  <IncidentsLayer />
                  <RelationshipLinesLayer />
                  <WeatherDataLayers />
                  <MapClickControl />
                  <MapMoveControl />
                  <SearchLayer />
                </Suspense>
              </MapboxMap>
              <Suspense fallback={null}><Legend /></Suspense>
            </div>
          </LayerFilterProvider>
        </div>
      </PopupProvider>
    </div>
  );
}

export default connect(mapState, mapDispatch)(MapViewComponent)
