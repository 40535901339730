import { useContext, useEffect } from 'react';
import { MapContext } from 'react-mapbox-gl';

import { MapExternalsContext } from "./MapExternalsControl.jsx";

export default function MapMoveControl(props) {
  const map = useContext(MapContext);
  const { dispatch } = useContext(MapExternalsContext);

  // Register the primary event handler with the map
  useEffect(() => {
    // Fire event when the map is moved
    map.on('moveend', handleOnViewportChanged);
    // Fire event when the map's fitBounds property changes
    map.on('idle', handleOnViewportChanged);
  }, []);

  const handleOnViewportChanged = (_) => {
    const viewportBounds = map.getBounds();
    dispatch({
      perform: "setViewportBounds",
      data: viewportBounds,
    });
  }

  return null;
}
