import { createSelector, createSlice } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { getIncidentBlocker } from '../../helpers'

const initialState = {
  incidents: [],
  performedInitialLoad: false,
  lastIncidentCreationTime: null,
  lastUserUpdate: null,
  lastFetchTime: null,
}

const IncidentSlice = createSlice({
  name: 'incidents',
  slice: 'incidents',
  initialState: initialState,
  reducers: {
    setIncidents(state, action) {
      const { fetchTime, incidents } = action.payload

      // If the user created, updated or deleted incidents after the
      // fetch started but before it finished, the new incident list
      // will not reflect those changes. To prevent that, we only
      // accept the new incidents if the fetch started after the last
      // user update.
      if (!state.lastUserUpdate || fetchTime > state.lastUserUpdate) {
        state.incidents = incidents
        state.lastFetchTime = fetchTime
        state.performedInitialLoad = true
      }
    },
    addNewIncident(state, action) {
      state.incidents.unshift(action.payload)

      const now = DateTime.now()
      state.lastIncidentCreationTime = now
      state.lastUserUpdate = now
    },
    updateIncident(state, action) {
      const updatedIncident = action.payload

      state.incidents = state.incidents.map((incident) => {
        if (incident.incident_id === updatedIncident.incident_id) {
          return updatedIncident
        }

        return incident
      })

      state.lastUserUpdate = DateTime.now()
    },
    removeIncident(state, action) {
      const deletedIncidentId = action.payload

      state.incidents = state.incidents.filter(
        (incident) => incident.incident_id !== deletedIncidentId,
      )

      state.lastUserUpdate = DateTime.now()
    },
    destroyIncidentState(state, action) {
      return initialState
    },
  },
})

export const selectOutages = createSelector(
  (state) => state.incidents.incidents,
  (incidents) => {
    const outages = {}

    incidents.forEach((incident) => {
      if (incident.incident_status_name !== 'resolved') {
        const outage = getIncidentBlocker(incident)

        if (outage) {
          if (outages[outage.incident_id]) {
            outages[outage.incident_id].related_incidents.push(incident)
          } else {
            outages[outage.incident_id] = {
              ...outage,
              related_incidents: [incident],
            }
          }
        }
      }
    })

    return outages
  },
)

export const {
  setIncidents,
  addNewIncident,
  updateIncident,
  removeIncident,
  destroyIncidentState,
} = IncidentSlice.actions

export default IncidentSlice.reducer
