import React, { Suspense, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Layer, MapContext } from 'react-mapbox-gl'
import { getAllAssetsByType } from '../../../../api/assets'
import PopupContext from './PopupContext'
import { DateTime } from 'luxon'

const PowerSupplyPopup = React.lazy(() => import('./PowerSupplyPopup'))

export const POWER_SUPPLIES_LAYER_ID = "power-supplies-layer"
const POWER_SUPPLIES_DATA_SOURCE_ID = "power-supplies-data-source"

export const POWER_SUPPLY_COLOR = "#DBB8B8"
export const POWER_SUPPLY_WITH_GENERATOR_COLOR = "#855D5D"
export const POWER_SUPPLY_LOW_FUEL_COLOR = "#AA8E41"

const PowerSuppliesLayer = () => {
  const [sourcesLoaded, setSourcesLoaded] = useState(false)
  const [lowFuelIds, setLowFuelIds] = useState([])
  const [nextUpdateTime, setNextUpdateTime] = useState(null)
  const layerData = useRef()

  const map = useContext(MapContext)
  const {
    currentPopupType,
    popupData,
    handleClosePopup,
  } = useContext(PopupContext)

  const updateFeature = (assetId, newGeneratorData) => {
    // Update power supply status without reloading the entire layer
    const feature = layerData.current.features.find(
      (feature) => feature.properties.asset_id === assetId
    )

    if (feature) {
      feature.properties.metadata.generator = newGeneratorData
      map.getSource(POWER_SUPPLIES_DATA_SOURCE_ID).setData(layerData.current)
      updateLowFuelIdList()
    }
  }

  const updateLowFuelIdList = useCallback(() => {
    const inOneHour = DateTime.now().plus({ hours: 1 })

    const lowFuelIds = []
    const nextUpdateTimeCandidates = []

    layerData.current.features.forEach((feature) => {
      const endTime = feature.properties.metadata.generator?.generator_end_time

      if (endTime) {
        const parsedEndTime = DateTime.fromISO(endTime)

        if (parsedEndTime <= inOneHour) {
          lowFuelIds.push(feature.properties.asset_id)
        } else {
          nextUpdateTimeCandidates.push(parsedEndTime.minus({ hours: 1 }))
        }
      }
    })

    setLowFuelIds(lowFuelIds)
    setNextUpdateTime(
      nextUpdateTimeCandidates.length > 0
      ? DateTime.min(...nextUpdateTimeCandidates)
      : null
    )
  }, [])

  // Set up mapa data sources
  useEffect(() => {
    const emptyCollection = {
      type: "FeatureCollection",
      features: []
    }

    map.addSource(POWER_SUPPLIES_DATA_SOURCE_ID, {
      type: "geojson",
      data: emptyCollection
    })

    // Fetch & load power supplies
    const loadPowerSupplies = async () => {
      const powerSupplies = await getAllAssetsByType('power supply', true)

      if (powerSupplies) {
        layerData.current = powerSupplies
        map.getSource(POWER_SUPPLIES_DATA_SOURCE_ID).setData(powerSupplies)
        updateLowFuelIdList()
      }
    }

    loadPowerSupplies()
    setSourcesLoaded(true)
  }, [map, updateLowFuelIdList])

  useEffect(() => {
    if (nextUpdateTime) {
      // Trigger a map update when the next power supply
      // starts running out of fuel. nextUpdateTime is the
      // time the next generator will have 60 minutes to go
      const interval = nextUpdateTime - DateTime.now()

      const timeout = setTimeout(updateLowFuelIdList, interval)

      // Clear the timeout on cleanup
      return () => clearTimeout(timeout)
    }
  }, [nextUpdateTime, updateLowFuelIdList])

  return (
    <>
      {sourcesLoaded && (
        <Layer
          id={POWER_SUPPLIES_LAYER_ID}
          sourceId={POWER_SUPPLIES_DATA_SOURCE_ID}
          type="circle"
          paint={{
            "circle-color": [
              'case',
              [
                'in',
                ['get', 'asset_id'],
                ['literal', lowFuelIds]
              ],
              POWER_SUPPLY_LOW_FUEL_COLOR,
              [
                'has',
                'generator',
                ['object', ['get', 'metadata']]
              ],
              POWER_SUPPLY_WITH_GENERATOR_COLOR,
              POWER_SUPPLY_COLOR
            ],
            "circle-radius": [
              "interpolate",
              ["linear"],
              ['zoom'],
              6,
              2,
              14,
              8
            ],
          }}
        />
      )}
      {currentPopupType === "powerSupply" && (
        <Suspense fallback={null}>
          <PowerSupplyPopup
            {...{popupData, handleClosePopup, updateFeature}}
          />
        </Suspense>
      )}
    </>
  )
}

export default PowerSuppliesLayer
