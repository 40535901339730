import { toaster } from 'evergreen-ui'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import {
  createAsset,
  searchAssets,
  getAssetTypes,
} from '../../../../api/assets'
import { formatAssetType } from '../../../../helpers'

import styles from '../../maps/Map.module.scss'

const CreateAssetForm = ({ incident, onAssetCreated, assetTyped }) => {
  const [assetName, setAssetName] = useState(assetTyped)
  const [assetType, setAssetType] = useState({ label: '', value: '' })
  const [validationErrors, setValidationErrors] = useState({})
  const [assetTypeOptions, setAssetTypeOptions] = useState()

  const { t } = useTranslation('incidents')

  useEffect(() => {
    getAssetTypes().then((res) => {
      let values = res.data.reduce((acc, type) => {
        if (type.asset_type_name !== 'service territory') {
          acc.push({
            label: formatAssetType(t, type.asset_type_name),
            value: type.asset_type_name,
            id: type.asset_type_id,
          })
        }
        return acc
      }, [])
      setAssetTypeOptions(values)
    })
  }, [t])

  const filterTypeOptions = (inputValue) => {
    return assetTypeOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    )
  }

  const loadTypeOptions = (inputValue, callback) => {
    return callback(filterTypeOptions(inputValue))
  }

  const createNewAsset = async (e) => {
    e.preventDefault()
    const validationResult = await searchAssets([assetType.value], assetName)
    if (validationResult.some((result) => result.asset_name === assetName)) {
      return setValidationErrors({ assetName: t('name_already_exists') })
    }
    if (!assetName)
      return setValidationErrors({ assetName: t('asset_name_required') })
    if (!assetType.value)
      return setValidationErrors({ assetType: t('asset_type_required') })
    setValidationErrors({})

    try {
      const asset = {
        asset_name: assetName,
        asset_description: '',
        asset_type_name: assetType.label.toLowerCase(),
        actively_monitor: false,
        location: { geojson: incident.location.geojson },
      }
      const createResult = await createAsset(asset)
      onAssetCreated(assetName, createResult.data.asset_id)
    } catch (e) {
      toaster.danger(t('common:something_went_wrong'))
    }
  }

  return (
    <div>
      <Form.Label className={styles.group_label}>
        {t('asset_name')} *
      </Form.Label>
      <Form.Control
        onChange={(val) => {
          setAssetName(val.currentTarget.value)
        }}
        value={assetName}
      />
      {validationErrors.assetName && (
        <Form.Text className="text-danger">
          {validationErrors.assetName}
        </Form.Text>
      )}
      <Form.Label className={styles.group_label}>
        {t('asset_type')} *
      </Form.Label>
      <AsyncSelect
        value={assetType}
        defaultOptions={assetTypeOptions}
        loadOptions={loadTypeOptions}
        onChange={setAssetType}
      />
      {validationErrors.assetType && (
        <Form.Text className="text-danger">
          {validationErrors.assetType}
        </Form.Text>
      )}
      <Button
        onClick={createNewAsset}
        style={{ marginTop: '5px' }}
        variant="success"
      >
        {t('create')}
      </Button>
    </div>
  )
}

export default CreateAssetForm
