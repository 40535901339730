import { get, patch } from './client.js'
import { fetchItemFromLocalStorage, saveItemsToLocalStorage } from './../helpers/localStore'

const aaaLSPrefix = "user-details"

export function getGlobalUserId() {
  return fetchItemFromLocalStorage("uid", "session")
}

export function getGlobalUserName() {
  return fetchItemFromLocalStorage("name", aaaLSPrefix)
}

export function getGlobalUserEmail() {
  return fetchItemFromLocalStorage("email", aaaLSPrefix)
}

export function getGlobalUserOrganizationId() {
  return fetchItemFromLocalStorage("orgid", "session")
}

export function setGlobalUserObject(name, email) {
  saveItemsToLocalStorage({
    name: name,
    email: email,
  }, aaaLSPrefix)
}

export function getSessionUser() {
  return get('/session/user')
}

export function getCurrentSession() {
  return get('/session')
}

export function updateSessionUser(jsonPatch) {
  return patch(
    '/session/user',
    {
      data: jsonPatch,
    },
  )
}

export function getOrganization(organization_id) {
  return get('/organizations/' + organization_id);
}

export function getSessionOrganization() {
  return get('/session/organization');
}

export function updateSessionOrganizationName(new_organization_name) {
  const jsonPatch = [
    {
      "op": "replace",
      "path": "/organization_name",
      "value": new_organization_name
    }
  ];

  return patch(
    '/session/organization',
    {
      data: jsonPatch,
    },
  );
}

export const setSessionUser = (userObj) => {
  const userName = userObj.first_name + " " + userObj.last_name

  if (window.heap) {
    window.heap.identify(userObj.user_id);
    window.heap.addUserProperties({
      "name": userName,
      "email": userObj.email_address,
      "organization_id": userObj.organization_id
    })
  }

  saveItemsToLocalStorage({
    name: userName,
    email: userObj.email_address,
  }, "user-details")
}
