import { useContext, useEffect } from 'react';
import { MapContext } from 'react-mapbox-gl';

import PopupContext from './PopupContext.jsx';

import {
  POINT_NODES_LAYER_ID, 
  POLYGON_NODES_LAYER_ID, 
  DATA_CENTERS_LAYER_ID, 
  OTHER_ASSETS_LAYER_ID,
} from './AssetsLayer.jsx';
import {
  INCIDENT_OUTAGE_POLYGONS_LAYER_ID,
  NEARBY_OUTAGE_POLYGONS_LAYER_ID,
} from './OutagesLayer'
import {INCIDENTS_LAYER} from './IncidentsLayer.jsx';
import { POWER_SUPPLIES_LAYER_ID } from './PowerSuppliesLayer.jsx';

export default function MapClickControl(props) {
  const map = useContext(MapContext);
  const { handleOpenPopup } = useContext(PopupContext);

  // Order of the layer ids here is important as we can query for a rendered
  // feature under the point at which the click event occured by each layer,
  // and ensuring that only the "top most" layer's click actions are performed.
  // The "top most" layer is mostly ordered by the size of the target area for
  // the click action.
  const LAYER_IDS = [
    POWER_SUPPLIES_LAYER_ID,
    POINT_NODES_LAYER_ID,
    DATA_CENTERS_LAYER_ID,
    OTHER_ASSETS_LAYER_ID,
    INCIDENTS_LAYER,
    INCIDENT_OUTAGE_POLYGONS_LAYER_ID,
    NEARBY_OUTAGE_POLYGONS_LAYER_ID,
    POLYGON_NODES_LAYER_ID,
  ];

  const fetchHandlerByLayerID = (layerId) => ({
    [POINT_NODES_LAYER_ID]: handleOnClickForAssets,
    [POLYGON_NODES_LAYER_ID]: handleOnClickForAssets,
    [INCIDENTS_LAYER]: handleOnClickForIncidentPins,
    [DATA_CENTERS_LAYER_ID]: handleOnClickForAssets,
    [OTHER_ASSETS_LAYER_ID]: handleOnClickForAssets,
    [POWER_SUPPLIES_LAYER_ID]: handleOnClickForPowerSupplies,
    [INCIDENT_OUTAGE_POLYGONS_LAYER_ID]: handleOnClickForOutages,
    [NEARBY_OUTAGE_POLYGONS_LAYER_ID]: handleOnClickForOutages,
  })[layerId];

  // Register the primary onclick handler with the map
  useEffect(() => {
    map.on('click', handleOnClick);
  }, []);

  const handleOnClick = (event) => {
    // HACK(yash): If this click event is registered where the target is not
    // a canvas element, we can safely? assume that the click event originated
    // from a popup, and we can exit the handler early to delegate the action to
    // the popup's click handler. We can't make use of the `currentPopupType`
    // field from the context because if the intention was to close the popup,
    // then the popup is closed and this field is null before this piece of
    // code is run.
    const nodeName = event?.originalEvent?.target?.nodeName;
    if (!!nodeName && nodeName !== 'CANVAS') {
      return;
    }

    for(let layerId of LAYER_IDS) {
      let features = map.queryRenderedFeatures(event.point, { layers: [layerId] });
      if (features?.length > 0) {
        // Fetch the click handler for the current layer, and call it.
        fetchHandlerByLayerID(layerId)(event, features);

        // We should return after we've handled the click action for the
        // top-most layer.
        return;
      }
    }

    // TODO: If we reach this part of the handler, maybe it's a good idea to
    // check if a popup is open and close it.
  };

  const handleOnClickForAssets = (event, features) => {
    const currentZoom = event.target.transform?.tileZoom;
    if (currentZoom > 9) {
      handleOpenPopup("asset", {
        ...(features[0].properties),
        coordinates: event.lngLat,
      });
    }
  };

  const handleOnClickForPowerSupplies = (event, features) => {
    handleOpenPopup("powerSupply", {
      ...(features[0].properties),
      coordinates: event.lngLat,
    })
  }

  const handleOnClickForIncidentPins = (event, features) => {
    handleOpenPopup("incident", {
      ...Object.entries(features[0].properties).reduce(
        (incident_properties, [key, value]) => ({
          ...incident_properties,
          [key]: value === "null" ? null : value,
        }),
        {}
      ),
      blockers: JSON.parse(features[0].properties?.blockers),
      location: {
        ...JSON.parse(features[0].properties.location),
        geojson: features[0].geometry,
      }
    });
  }

  const handleOnClickForOutages = (event, features) => {
    handleOpenPopup("outage", {
      ...features[0].properties,
      coordinates: event.lngLat,
    })
  }

  return null;
}
