import React, { useState, createContext } from 'react'

import { recordAnalyticEvent } from '../../../../api/analytics'

export const PopupContext = createContext()

export function PopupProvider({children}) {
  const [ currentPopupType, setPopupType ] = useState(null)
  const [ popupData, setPopupData ] = useState({})
  const [ flipcard, setFlipcard ] = useState(false)

  /**
   * Method to perform popup opening
   * @param {("incident"|"partnerIncident"|"outage"|"asset")} type The type of popup being opened
   * @param {object} value
   */
  const handleOpenPopup = (type, value) => {
    setPopupType(null)
    setPopupData({})
    setPopupData(value)
    setPopupType(type)

    recordAnalyticEvent(`Open ${type} on Map`, {
      "pin_data": value
    })
  }

  /**
   * Flip to the other side of a card (right now just the blocker on a normal incident)
   */
  const togglePopupFlip = () => setFlipcard(!flipcard)

  /**
   * Close the currently open popup.
   */
  const handleClosePopup = () => {
    recordAnalyticEvent(`Close ${currentPopupType} on Map`)

    setPopupType(null)
    setPopupData({})
    setFlipcard(false)
  }

  const fullContext = {
    currentPopupType,
    popupData,
    flipcard,
    handleOpenPopup,
    togglePopupFlip,
    handleClosePopup
  }

  return (
    <PopupContext.Provider value={fullContext}>
      {children}
    </PopupContext.Provider>
  )
}

export default PopupContext
