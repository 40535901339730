import axios from 'axios'

const MapBoxBaseURL = "https://api.mapbox.com"

export function incidentMapboxGeocode(search_query) {
  const url = MapBoxBaseURL + "/geocoding/v5/mapbox.places/" + search_query + ".json?access_token=" + window.MAPBOX_API_KEY;

  return axios.get(url)
  .then(function(res) {
    return res.data.features;
  })
}

export function incidentMapboxReverseGeocode(longitude, latitude) {
  const url = MapBoxBaseURL + "/geocoding/v5/mapbox.places/" + longitude + "," + latitude + ".json?access_token=" + window.MAPBOX_API_KEY;

  return axios.get(url)
  .then(function(res) {
    let featureDict = {};

    if (res && res.data && res.data.features) {
      for (const feature of res.data.features) {
        if (feature.place_type[0] === "address" && !!feature.text) {
          featureDict[feature.place_type[0]] = `${!!feature.address ? feature.address + " " : " "}${feature.text}`;
        } else {
          featureDict[feature.place_type[0]] = feature.text;
        }
      }
    }
    return featureDict
  })
}
