import { getGlobalUserOrganizationId } from './aaa.js'
import { get, patch, post, del } from './client.js'

import { toaster } from 'evergreen-ui'

const assetCache = {}

export async function getAllAssetsByType(asset_type_name = null, bypass_cache = false) {
  const url = '/assets/bulk'

  const params = new URLSearchParams(
    `fields=asset_id&fields=asset_name&fields=asset_type_name&fields=metadata&bypass_cache=${bypass_cache}`)

  if (asset_type_name) {
    params.append("asset_type_name", asset_type_name)
  }

  const response = await get(
    url + '?' + params.toString(),
    {
      headers: {
        'Accept': 'application/geo+json',
      }
    },
  );

  return response.data;
}

export async function getOtherAssets() {
  const typesToExclude = [
    "node",
    "service territory",
    "data center",
    "network line",
    "power supply",
    "wire center"
  ]

  const url = '/assets/bulk'

  const params = new URLSearchParams(
    "fields=asset_id&fields=asset_name&fields=asset_type_name")

  typesToExclude.forEach((type) => {
    params.append('asset_type_name_exclude', type)
  })

  const response = await get(
    url + '?' + params.toString(),
    {
      headers: {
        'Accept': 'application/geo+json',
      }
    },
  );

  return response.data;
}

export async function searchAssets(assetTypes, searchText, limit = 5) {
  const typeTerms = assetTypes.map((assetTypeName) => ({
    "field": "asset_type_name",
    "type": "string",
    "operator": "equals",
    "value": assetTypeName
  }))

  const search = {
    "conditions": {
      "coupler": "AND",
      "terms": [
        {
          "field": "organization_id",
          "type": "string",
          "operator": "equals",
          "value": getGlobalUserOrganizationId()
        },
        {
          "field": "asset_name",
          "type": "string",
          "operator": "contains",
          "value": searchText
        },
        {
          "coupler": "OR",
          "terms": typeTerms
        }
      ]
    }
  };

  const response = await post(
    `/assets/search?limit=${limit}`,
    {
      data: search,
    },
  );

  return response.data
}

export async function organizationHasAssets() {
  let search = {
    "conditions": {
      "coupler": "AND",
      "terms": [
        {
          "field": "organization_id",
          "type": "string",
          "operator": "equals",
          "value": getGlobalUserOrganizationId()
        },
      ]
    }
  };

  const response = await post(
    `/assets/search?limit=1`,
    {
      data: search,
    },
  );

  return response.data.length > 0
}

export function getAsset(id, bypassCache = false) {
  if (!bypassCache && assetCache[id]) {
    return assetCache[id]
  }

  const assetData = get('/assets/' + id)
  assetCache[id] = assetData
  return assetData
}

export function createAsset(asset) {
  return post(
    '/assets',
    {
      data: asset,
    }
  )
  .then((res) => {
    toaster.success("New asset created.", { duration: 3 })
    return res;
  })
}

export function updateAsset(assetId, jsonPatch, notifyOnSuccess = true) {
  return patch(
    '/assets/' + assetId,
    {
      data: jsonPatch,
    },
  )
  .then((res) => {
    if (notifyOnSuccess) {
      toaster.success("The asset was successfully updated.", { duration: 3 })
    }

    return res;
  })
}

export function deleteAsset(assetId) {
  return del('/assets/' + assetId)
  .then((res) => {
    toaster.success("The asset was successfully deleted.", { duration: 3 })
    return res;
  })
}

export function getAssetTypes() {
  return get("/asset-types")
}

export function updateGenerator(assetId, generatorData) {
  return patch(
    `/assets/${assetId}/generator`,
    {
      data: generatorData,
    },
  )
  .then((res) => {
    return res;
  })
}

export function deleteGenerator(assetId) {
  return del(`/assets/${assetId}/generator`)
  .then((res) => {
    return res;
  })
}
