import React, { Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import PrivateRoute from '../common/PrivateRoute'
import {
  getDefaultRoute,
  refreshDefaultRoute,
  restoreSession,
} from '../api/auth'

import MapComponent from './components/maps/MapComponent'

import { MapExternalsProvider } from './components/maps/partials/MapExternalsControl'
import { ThemeProvider } from 'evergreen-ui'

import Header from '../common/header/HeaderComponent'
import UpdateChecker from '../common/update-checker/UpdateChecker'

import styles from './global.module.css'
import GisualEvergreenTheme from './GisualEvergreenTheme'
import LanguageProvider from '../common/LanguageProvider/languageProvider'

import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

const IncidentView = React.lazy(() =>
  import('./components/incidents/IncidentViewComponent'),
)
const OverviewComponent = React.lazy(() =>
  import('./components/overview/RegionalMapComponent'),
)
const UserProfileComponent = React.lazy(() =>
  import('./components/user/ProfileComponent'),
)
const ForgotPasswordComponent = React.lazy(() =>
  import('./components/login/ForgotPasswordComponent'),
)
const ResetPasswordComponent = React.lazy(() =>
  import('./components/login/ResetPasswordComponent'),
)
const MaintenanceModeComponent = React.lazy(() =>
  import('./components/maintenancemode/MaintenanceModeComponent'),
)
const LoginComponent = React.lazy(() =>
  import('./components/login/LoginComponent'),
)
const LogoutComponent = React.lazy(() =>
  import('./components/login/LogoutComponent'),
)
// const SignUpComponent = React.lazy(() => import('./components/login/SignUpComponent'))
const GisualUsersComponent = React.lazy(() =>
  import('./components/user/GisualUsersComponent'),
)

function AppContainer(props) {
  restoreSession()

  const defaultRoute = getDefaultRoute()

  useEffect(() => {
    // Refresh the default route some time after loading the page.
    // This ensures all users will load to the correct default
    // page even if we add/remove assets and they don't log out
    // without having a negative effect on load times.
    const timeout = setTimeout(refreshDefaultRoute, 5000)
    return () => clearTimeout(timeout)
  })

  if (window.GISUAL_MAINTENANCE_MODE === true) {
    return (
      <div>
        <Suspense fallback={null}>
          <MaintenanceModeComponent />
        </Suspense>
      </div>
    )
  } else {
    return (
      <ErrorBoundary>
        <Router>
          <div className={styles.AppContainer}>
            <ThemeProvider value={GisualEvergreenTheme}>
              <LanguageProvider languages={['en', 'es']}>
                <MapExternalsProvider>
                  <Suspense fallback={null}>
                    <ErrorBoundary>
                      <Header />
                      <UpdateChecker />
                    </ErrorBoundary>
                  </Suspense>
                  <Suspense fallback={null}>
                    <ErrorBoundary>
                      <Routes>
                        <Route path="/list" element={<PrivateRoute><IncidentView /></PrivateRoute>} />
                        <Route path="/map/:id?/:type?" element={<PrivateRoute><MapComponent /></PrivateRoute>} />
                        <Route path="/loc/:z?/:x?/:y?" element={<PrivateRoute><MapComponent /></PrivateRoute>} />
                        <Route path="/regional-map/*" element={<PrivateRoute><OverviewComponent /></PrivateRoute>} />
                        <Route path="/user/" element={<PrivateRoute><UserProfileComponent /></PrivateRoute>} />
                        <Route path="/user/:reset" element={<PrivateRoute><UserProfileComponent /></PrivateRoute>} />

                        <Route path="/login" element={<LoginComponent />} />
                        <Route path="/logout" element={<LogoutComponent />} />
                        <Route path="/forgot-password" element={<ForgotPasswordComponent />} />
                        <Route path="/reset-forgotten-password/:token?" element={<ResetPasswordComponent />} />

                        <Route path="/users" element={<PrivateRoute requiresAdmin><GisualUsersComponent /></PrivateRoute>} />
                        <Route path="/users/add" element={<PrivateRoute requiresAdmin><GisualUsersComponent /></PrivateRoute>} />

                        <Route path="/" element={<Navigate to={defaultRoute || '/map'} replace />} />
                        <Route path="*" element={<Navigate to={defaultRoute || '/map'} replace />} />
                      </Routes>
                    </ErrorBoundary>
                  </Suspense>
                </MapExternalsProvider>
              </LanguageProvider>
            </ThemeProvider>
          </div>
        </Router>
      </ErrorBoundary>
    )
  }
}

export default AppContainer
