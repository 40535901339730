import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Layer, MapContext } from "react-mapbox-gl"
import { useSelector } from 'react-redux';
import { computeCentroidForMultiPolygon, computeCentroidForPolygon } from '../../../../helpers/extents';
import { getLineGeojson } from '../../../../helpers/geometry';
import { NODE_AFFECTED_COLOR } from './AssetsLayer';
import { INCIDENT_COLOR_POWER_OUT } from './IncidentsLayer'
import PopupContext from './PopupContext';
import { getAsset } from '../../../../api/assets';
import { selectOutages } from '../../../store/IncidentSlice';

export const INCIDENTS_RELATIONSHIP_LINES_LAYER = "incidents-relationship-lines-layer";
export const NODE_RELATIONSHIP_LINES_LAYER_ID = "node-relationship-lines-layer";

const INCIDENTS_RELATIONSHIP_LINES_SOURCE_ID = "incidents-relationship-lines-source";
const NODE_RELATIONSHIP_LINES_SOURCE_ID = "node-relationship-lines-source";

const getDependencyLocation = async (dependent) => {
  let response = await getAsset(dependent.record_id);

  let record = response.data;

  const geojson = record.location.geojson
  let dependentLocation

  if (geojson.type === 'Point') {
    dependentLocation = geojson.coordinates
  } else if (geojson.type === 'Polygon') {
    dependentLocation = computeCentroidForPolygon(geojson.coordinates)
  } else if (geojson.type === 'MultiPolygon') {
    dependentLocation = computeCentroidForMultiPolygon(geojson.coordinates)
  }

  return dependentLocation
}

const PartialRelationshipLinesLayer = ({ layerId, sourceId, lineColor, lineFeatures }) => {
  const map = useContext(MapContext);

  const [sourceLoaded, setSourceLoaded] = useState(false);

  // Add source layer
  useEffect(() => {
    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    })

    setSourceLoaded(true)
  }, [map, sourceId])

  // Update layer when info ins updated
  useEffect(() => {
    try {
      map.getSource(sourceId).setData({
        type: "FeatureCollection",
        features: lineFeatures
      })
    } catch {}
  }, [map, lineFeatures, sourceId])

  return (
    sourceLoaded && <Layer
      id={layerId}
      sourceId={sourceId}
      type="line"
      paint={{
        'line-width': 2,
        'line-color': lineColor,
        'line-dasharray': [2, 1]
      }}
    />
  );
};

const RelationshipLinesLayer = () => {
  const {
    currentPopupType,
    popupData,
  } = useContext(PopupContext);

  const incidents = useSelector((state) => state.incidents.incidents)
  const outages = useSelector(selectOutages)

  const [incidentRelationshipLines, setIncidentRelationshipLines] = useState([]);
  const [nodeRelationshipLines, setNodeRelationshipLines] = useState([]);

  const updateOutageRelationshipLines = useCallback(async (outages) => {
    const nodeLines = []
    const incidentLines = []

    await Promise.all(outages.map(async (outage) => {
      if (outage) {
        const outageIncidentLines = outage.related_incidents.map((incident) => (
          getLineGeojson(
            outage.location.geojson.coordinates,
            incident.location.geojson.coordinates
          )
        ))

        const dependents = outage.related_incidents.map((incident) => (
          incident.dependents.filter((dependent) => (
            dependent.record_type === "assets"
          ))
        )).flat()

        const outageNodeLines = await Promise.all(
          dependents.map(async (dependent) => (
            getLineGeojson(
              outage.location.geojson.coordinates,
              await getDependencyLocation(dependent),
            )
          )
        ))

        incidentLines.push(...outageIncidentLines)
        nodeLines.push(...outageNodeLines)
      }
    }))

    setIncidentRelationshipLines(incidentLines)
    setNodeRelationshipLines(nodeLines)
  }, [])

  useEffect(() => {
    if (currentPopupType === "incident") {
      updateOutageRelationshipLines(
        popupData.blockers.map(({ incident_id }) => (
          outages[incident_id]
        ))
      )
    } else if (currentPopupType === "outage") {
      updateOutageRelationshipLines([
        outages[popupData.incident_id]
      ])
    } else if (currentPopupType === "asset") {
      const assetIds = popupData.asset_ids ? JSON.parse(popupData.asset_ids) : []

      if (popupData.asset_id) {
        assetIds.push(popupData.asset_id)
      }

      const relatedIncidents = incidents.filter((incident) => {
        const dependents = incident.dependents
        return dependents.some((dependent) => (
          dependent.record_type === "assets" && assetIds.includes(dependent.record_id)
        ))
      })

      const relatedOutages = relatedIncidents.filter((blocker) => (
        blocker.uncorrelated_at === null
      )).map(
        (incident) => incident.blockers.map(( blocker ) => (
          outages[blocker.incident_id]
        ))
      ).flat()

      updateOutageRelationshipLines(relatedOutages)
    } else {
      setIncidentRelationshipLines([])
      setNodeRelationshipLines([])
    }
  }, [currentPopupType, popupData, incidents, outages, updateOutageRelationshipLines])

  return (
    <Fragment>
      <PartialRelationshipLinesLayer
        layerId={INCIDENTS_RELATIONSHIP_LINES_LAYER}
        sourceId={INCIDENTS_RELATIONSHIP_LINES_SOURCE_ID}
        lineColor={INCIDENT_COLOR_POWER_OUT}
        lineFeatures={incidentRelationshipLines}
      />
      <PartialRelationshipLinesLayer
        layerId={NODE_RELATIONSHIP_LINES_LAYER_ID}
        sourceId={NODE_RELATIONSHIP_LINES_SOURCE_ID}
        lineColor={NODE_AFFECTED_COLOR}
        lineFeatures={nodeRelationshipLines}
      />
    </Fragment>
  )
}

export default RelationshipLinesLayer;
