import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Col, Button, Collapse, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import styles from '../../maps/Map.module.scss'
import sideSheetStyles from '../../../../common/incident-side-sheet/incidentsheet.module.css'

const TicketInformationPanel = ({ intelRequest, updateIntelRequest }) => {
  const [savingIntelRequest, setSavingIntelRequest] = useState({})
  const [collapseTI, setCollapseTI] = useState(true)

  const { t } = useTranslation('incidents')

  useEffect(() => {
    setSavingIntelRequest(intelRequest)
  }, [intelRequest])

  useEffect(() => {
    updateIntelRequest(savingIntelRequest)
  }, [savingIntelRequest])

  const handleChangeTicketInformation = (e) => {
    const { value } = e.target

    setSavingIntelRequest((prevState) => ({
      ...prevState,
      metadata: {
        ...prevState.metadata,
        search_id: value,
      },
    }))
  }

  return (
    <div className={styles.ticketInformation}>
      <Row>
        <Button
          onClick={() => setCollapseTI(!collapseTI)}
          aria-controls="collapseTI-text"
          aria-expanded={collapseTI}
          className={sideSheetStyles.collapseSectionHeader}
        >
          <FontAwesomeIcon
            icon={collapseTI ? faAngleDown : faAngleRight}
          ></FontAwesomeIcon>{' '}
          {t('ticket_information')}
        </Button>
      </Row>
      <Collapse in={collapseTI}>
        <div className={sideSheetStyles.collapseSectionBody}>
          <Row>
            <Col>
              <Form.Label className={styles.group_label}>
                {t('ticket_id')}
              </Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                value={savingIntelRequest?.metadata?.search_id}
                onChange={handleChangeTicketInformation}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  )
}
export default TicketInformationPanel
