import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { DEFAULT_MAP_BOUNDS } from '../../helpers/extents'

const AssetSlice = createSlice({
  name: 'assets',
  slice: 'assets',
  initialState: {
    "serviceTerritories": {
      type: "FeatureCollection",
      features: []
    },
    "territoryBounds": [],
    "networkLines": {
      type: "FeatureCollection",
      features: [],
      loaded: false
    }
  },
  reducers: {
    setAssets(state, action) {
      let incoming = action.payload
      return incoming
    },
    setTerritories(state, action) {
      let incoming = action.payload
      state.serviceTerritories = incoming
    },
    setBounds(state, action) {
      let incoming = action.payload
      state.territoryBounds = incoming
    },
    resetBounds(state, action) {
      state.territoryBounds = DEFAULT_MAP_BOUNDS
    },
    setNetworkLines(state, action) {
      let incoming = action.payload

      state.networkLines = incoming
      state.networkLines.loaded = true
    },
    addTerritory(state, action) {
      let incoming = action.payload
      state.serviceTerritories.features.push(incoming)
    },
    destroyAssetState(state, action) {
      return {
        "serviceTerritories": {
          type: "FeatureCollection",
          features: []
        },
        "territoryBounds": [],
        "networkLines": {
          type: "FeatureCollection",
          features: [],
          loaded: false
        }
      }
    },
    resetNodeCacheToken(state, action) {
      state.nodeSessionToken = uuidv4()
    }
  }
})

export const {
  setAssets,
  setTerritories,
  setBounds,
  resetBounds,
  setNetworkLines,
  addTerritory,
  destroyAssetState,
  resetNodeCacheToken,
} = AssetSlice.actions

export default AssetSlice.reducer
