import { get, patch, post, del } from './client.js'
import { toaster } from 'evergreen-ui'
import { DateTime } from 'luxon'
import { computeDuration } from '../helpers/index.js'
import { getGlobalUserOrganizationId } from './aaa.js'
import { parseLinkHeader } from '@web3-storage/parse-link-header'
import { t } from 'i18next'

export function getAllIncidentPriorities() {
  return get('/incident-priorities')
}

export function getAllIncidentStatuses() {
  return get('/incident-statuses')
}

export function getAllIncidentTypes() {
  return get('/incident-types')
}

export function createIncidentType(newtype) {
  return post('/incident-types', {
    data: {
      incident_type_name: newtype,
    },
  })
}

const MAX_UNCORRELATED_HOURS = 2

export async function getCurrentIncidents() {
  const response = await get('/incidents/current')

  // Filter out incidents older than MAX_UNCORRELATED_HOURS hours without correlated intel
  if (response.data) {
    response.data = response.data.filter((incident) => {
      const timeSinceCreated = computeDuration(incident.created)

      return (
        incident.blockers.length > 0 ||
        timeSinceCreated.as('hours') < MAX_UNCORRELATED_HOURS
      )
    })

    response.data.forEach((i) => (i.created = DateTime.fromISO(i.created)))
    response.data.sort((a, b) => b.created - a.created)
  }

  return response.data
}

const incidentSearchTerms = [
  {
    coupler: 'OR',
    terms: [
      {
        field: 'incident_status_name',
        type: 'string',
        operator: 'not_equals',
        value: 'resolved',
      },
      // If the incident is marked as resolved, let's show them for about
      // MAX_UNCORRELATED_HOURS hours after the last updated time.
      {
        field: 'updated',
        type: 'date',
        operator: 'greater_or_equal',
        value: DateTime.utc()
          .minus({ hours: MAX_UNCORRELATED_HOURS })
          .toString(),
      },
    ],
  },
]

export async function getIncidents(pagination = {}, limit = 250) {
  const queryURL = '/incidents/search'

  const params = getIncidentParams()

  if (limit > 0) {
    params.append('limit', limit)
  }

  // We need to get deleted dependencies to be able to show resolved times for outages
  params.append('include_deleted', true)

  if (pagination?.key && pagination?.direction) {
    params.append(pagination.direction, pagination.key)
  } else {
    // Sort params are already in the pagination key and must only be included
    // if the pagination key is not present
    params.append('sort_field', 'created')
    params.append('sort_direction', 'descending')
  }

  const search = {
    conditions: {
      coupler: 'AND',
      terms: [
        ...incidentSearchTerms,
        {
          field: 'organization_id',
          type: 'string',
          operator: 'equals',
          value: getGlobalUserOrganizationId(),
        },
        // Extra filter to remove deleted incidents, since we are passing include_deleted=true below to get deleted dependencies
        {
          field: 'deleted',
          type: 'date',
          operator: 'is_null',
        },
      ],
    },
  }

  const response = await post(queryURL + '?' + params.toString(), {
    data: search,
  })

  // Filter out incidents older than MAX_UNCORRELATED_HOURS hours without correlated intel

  if (response?.data) {
    response.data = response.data.filter((incident) => {
      const timeSinceCreated = computeDuration(incident.created)
      return (
        incident.blockers.length > 0 ||
        timeSinceCreated.as('hours') < MAX_UNCORRELATED_HOURS
      )
    })
  }

  const links = parseLinkHeader(response.headers.link)
  let next = null

  if (links?.next?.url) {
    const nextUrl = new URL(links.next.url)
    next = nextUrl.searchParams.get('next')
  }

  return {
    incidents: response?.data,
    next,
  }
}

export function createIncident(incident) {
  return post('/incidents', {
    data: incident,
  })
    .then((res) => {
      toaster.success(t('incidents:incident_created'))
      return res
    })
    .catch((error) => {
      if (error.error.response.status === 422) {
        toaster.danger(t('incidents:invalid_incident_data'))
      } else {
        toaster.danger(t('incidents:error_creating_incident'))
      }
      return Promise.reject(error)
    })
}

export function getIncident(incident_id, extraHeaders = {}) {
  const params = getIncidentParams()

  return get('/incidents/' + incident_id + '?' + params.toString(), {
    ...extraHeaders,
  })
}

export function getOutageById(incident_id) {
  return get('/incidents/' + incident_id)
}

export function updateIncidentWithPatch(incident_id, jsonPatch) {
  return patch('/incidents/' + incident_id, {
    data: jsonPatch,
  })
}

export function deleteIncident(incident_id) {
  return del('/incidents/' + incident_id).then((res) => {
    toaster.success(t('incidents:incident_deleted'))
    return res
  })
}

function getIncidentParams() {
  const params = new URLSearchParams()

  params.append('include_deleted_dependencies', 'false')
  params.append('get_recursive_dependencies', 'true')
  params.append('recursive_dependency_type', 'dependents')
  params.append('recursive_record_types', 'assets')

  return params
}
