import { get, patch, post } from './client'

export function getOutagesInServiceTerritoryAsGeoJSON() {
  return get(
    '/service-territory-outages',
    {
      headers: {
        Accept: 'application/geo+json',
      }
    },
  )
}

export function getTopUtilitiesByNumAffected() {
  return get('/utilities-by-num-affected')
}

export function getRegionalPowerOutages(countryCode) {
  return get(`/outages/power/regional?country=${countryCode}`)
}

export function getRegionalNetworkOutages() {
  return post(
    '/outages/network/regional',
    {
      data: {
        country: 'us',
      },
    },
  )
}

export function updateRegionalNetworkOutageFeedback(outageId, markedAsUseful) {
  return patch(
    `/outages/network/regional/${outageId}`,
    {
      data: {
        marked_as_useful: markedAsUseful,
      },
    },
  )
}
